export const mapStudentProblemData = (data) => {
    return data.map(item => ({
      problem_set_id: item.problem_set_id,
      problem_set_name:item.problem_set_name,
      student_name: item.student_name,
      student_answer: item.student_answer,
      student_problem_data_id:item.student_problem_data_id,
      incorrect_problems:item.is_correct,
      score: parseFloat(item.score),
      solved_at: new Date(item.solved_at),
    }));
  };
  