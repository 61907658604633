/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { css, useTheme } from '@emotion/react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement } from 'chart.js';
import buttonStyles from '../../style/buttonStyles';


ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement);

const API_URL = process.env.REACT_APP_API_URL;

const styles = (theme) => ({
  resetButton: css`
  all: unset;
  button {
    all: unset;
  }
`,
  header: css`
    width: 100%;
    color: black;
    display: flex;
    flex-direction: column;
    padding: 10px 20px;
    margin-top:30px;

  `,
  headerTop: css`
    align-items: center;
    justify-content: space-between;;
    margin-bottom: 10px;
  `,
  logoSection: css`
    display: flex;
    align-items: center;
    .logo {
      width: 40px;
      height: 40px;
      margin-right: 10px;
    }
    .title {
      font-size: 24px;
      font-weight: bold;
      margin-right: 20px;
    }
  `,
  statsSection: css`
    display: flex;
    align-items: center;
    margin-top:10px;
    .stat {
      margin-right: 20px;
      font-size: 16px;
      color: #666;
    }
  `,
  headerBottom: css`
    display: flex;
    margin-top:10px;
    align-items: center;
    justify-content: flex-start;
    .button {
 
    }
    .selected {
      background-color: #d5d5d5;
    }
  `,
  button:css`
  margin-right: 10px;
  padding: 10px 20px;
  border: none;
  background-color: #f5f5f5;
  color: black;
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    background-color: #e5e5e5;
  }
  `,
  mainContent: css`
    display: flex;
    flex-direction: column;
    padding: 20px;
    font-family: Arial, sans-serif;
    color: #333;
    background-color: ${theme.colors.background};
  `,
  chartContainer: css`
    margin-top: 20px;
  `,
});

const Data = () => {
  const [viewMode, setViewMode] = useState('qrcode');

  const theme = useTheme();
  const style = styles(theme);




  const renderContent = () => {
    switch (viewMode) {
      case 'qrcode':
       return <div css={style.chartContainer}></div>;
    //  case 'chart':
     //   return <div css={style.chartContainer}></div>;
      case 'analysis':
         return <div css={style.chartContainer}></div>;
 
      default:
       return <div css={style.chartContainer}></div>;
    }
  };

  return (
    <div>
      <div css={style.header}>
        <div css={style.headerTop}>

        </div>
        <div css={style.headerBottom}>
          <button css={buttonStyles(theme)}  className={viewMode === 'qrcode' ? 'selected' : ''} onClick={() => setViewMode('qrcode')}>수업 시작</button>
       {/*   <button css={buttonStyles(theme)}  className={viewMode === 'chart' ? 'selected' : ''} onClick={() => setViewMode('chart')}>질문 분석</button> */}
          <button css={buttonStyles(theme)}  className={viewMode === 'analysis' ? 'selected' : ''} onClick={() => setViewMode('analysis')}>학생 자료 조회</button>

        </div>
      </div>
      <div css={style.mainContent}>
        {renderContent()}
      </div>
    </div>
  );
};

export default Data;
