import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {jwtDecode} from 'jwt-decode';

const API_URL = process.env.REACT_APP_API_URL;

const useAuth = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userName, setUserName] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const verifyToken = async () => {
      const token = localStorage.getItem('access_token');
      
      if (!token) {
        navigate('/');
        return;
      }

      try {
        const decodedToken = jwtDecode(token);
        const role = decodedToken.user_role; // 예시: 역할 정보가 토큰에 포함되어 있다고 가정합니다.
        if (role === 'teacher') {
          setIsLoggedIn(true);
          setUserName('Teacher');
        } else if (role === 'student') {
          setIsLoggedIn(true);
          setUserName('Student');
        } else {
          throw new Error('Invalid role');
        }
      } catch (error) {
        navigate('/');
        console.error(error);
      }
    };

    verifyToken();
  }, [navigate]);

  return { isLoggedIn, userName, setIsLoggedIn };
};

export default useAuth;
