import React from 'react';
import {GoogleLogin} from "@react-oauth/google";
import {GoogleOAuthProvider} from "@react-oauth/google";
import axios from 'axios';

const GOOGLE_CLIENT_ID = process.env.REACT_APP_CLIENT_ID ;

const API_URL = process.env.REACT_APP_API_URL;

const GoogleLoginButton = ({setIsLoggedIn, checkUserRole}) => {

    const clientId = GOOGLE_CLIENT_ID
    return (
        <>
            <GoogleOAuthProvider clientId={clientId}>

            <GoogleLogin
                        onSuccess={async (res) => {

                            try {
                                const response = await axios.post(`${API_URL}/auth/google-login`, {credential : res.credential});
                                if (response.data && response.data.access_token) {
                                    setIsLoggedIn(true);
                                    localStorage.setItem('access_token', response.data.access_token); // 토큰 저장

                                    // 추가 권한 확인 요청
                                    checkUserRole(response.data.access_token);
                                } else {
                                    setIsLoggedIn(false);
                                    alert('회원가입을 성공하였습니다.');
                                }
                            } catch (error) {
                                console.error('로그인 요청 에러:', error);
                                setIsLoggedIn(false);
                            }
                        }}
                        onFailure={(err) => {
                            console.log('error');
                        }}
                    />

            </GoogleOAuthProvider>
        </>
    );
};

export default GoogleLoginButton
