/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { css, useTheme } from '@emotion/react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement } from 'chart.js';
import Chart from './Chart';
import Unsolved from './Unsolved';
import useClasses from '../../hooks/useClasses';
import useStudentData from '../../hooks/useStudentProblemData';
import useStatistics from '../../hooks/useStatistics';
import buttonStyles from '../../style/buttonStyles';
import selectStyles from '../../style/selectStyles';
import api from '../../utils/Auth'; // 수정된 axios 인스턴스를 import 합니다.

const API_URL = process.env.REACT_APP_API_URL;

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement);

const styles = (theme) => ({
  resetButton: css`
    all: unset;
    button {
      all: unset;
    }
  `,
  header: css`
    width: 100%;
    color: black;
    display: flex;
    flex-direction: column;
    padding: 10px 20px;
    margin-top: 30px;
  `,
  headerTop: css`
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  `,
  logoSection: css`
    display: flex;
    align-items: center;
    .logo {
      width: 40px;
      height: 40px;
      margin-right: 10px;
    }
    .title {
      font-size: 24px;
      font-weight: bold;
      margin-right: 20px;
    }
  `,
  statsSection: css`
    display: flex;
    align-items: center;
    margin-top: 10px;
    .stat {
      margin-right: 20px;
      font-size: 16px;
      color: #666;
    }
  `,
  headerBottom: css`
    display: flex;
    margin-top: 10px;
    align-items: center;
    justify-content: flex-start;
    .button {
    }
  `,
  mainContent: css`
    display: flex;
    flex-direction: column;
    padding: 20px;
    font-family: Arial, sans-serif;
    color: #333;
  `,
  chartContainer: css`
    margin-top: 20px;
  `,
  resultsendButton: css`
    margin-left: 10px;
    padding: 10px 20px;
    background-color: ${theme.colors.brandColor4};
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
      background-color: #0056b3;
    }
  `,
  csvDownloadButton: css`
    margin-left: 10px;
    padding: 10px 20px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
      background-color: #218838;
    }
  `
});

const Dashboard = () => {
  const [selectedProblemSet, setSelectedProblemSet] = useState('');
  const [selectedClass, setSelectedClass] = useState('');
  const [viewMode, setViewMode] = useState('chart');
  const theme = useTheme();
  const style = styles(theme);

  const { classes } = useClasses();
  const { studentData, fetchData } = useStudentData(selectedClass);
  const statistics = useStatistics(selectedProblemSet, studentData);

  const renderContent = () => {
    switch (viewMode) {
      case 'chart':
        return <div css={style.chartContainer}><Chart studentData={studentData} selectedProblemSet={selectedProblemSet} selectedClass={selectedClass} fetchData={fetchData} /></div>;
      case 'analysis':
        return <div css={style.chartContainer}><Unsolved studentData={studentData} selectedProblemSet={selectedProblemSet} submit={statistics.uniqueStudents} /></div>;
      default:
        return null;
    }
  };

  const sendvisible = async () => {
    const confirmation = window.confirm('풀이결과를 학생에게 전송하시겠습니까? 현재까지 채점된 정보가 모두 학생에게 전달됩니다.');
    if (!confirmation) {
      return; // If the user cancels, simply return without submitting
    }

    if (selectedClass !== '' && selectedProblemSet !== '') {
      try {
        const response = await api.post(`${API_URL}/teachers/classrooms/${selectedClass}/students/problem-data/visible`,
          { problem_set_id: selectedProblemSet }
        );
        if (response.data) {
          alert('현재까지 누적된 풀이기록 결과가 학생에게 전송되었습니다!')
        }
      } catch (error) {
        console.error('Error sendvisible! class:', error);
      }
    } else {
      alert('정답과 문제지를 모두 선택해주세요!')
    }
  };

  const generateCSVData = () => {
    const data = studentData.filter(data => data.problem_set_id === selectedProblemSet);
    const csvRows = [
      ["학생번호", "학생이름", "문제집이름", "최초 점수", "평균 점수"]
    ];

    const studentMap = new Map();

    data.forEach(student => {
      if (!studentMap.has(student.student_name)) {
        studentMap.set(student.student_name, []);
      }
      studentMap.get(student.student_name).push(student);
    });

    studentMap.forEach((records, studentName) => {
      const studentNumber = studentName.slice(-2);
      const name = studentName.slice(0, -3);
      const problemSetName = records[0].problem_set_name;

      const initialRecord = records.reduce((earliest, current) => {
        return new Date(current.solved_at) < new Date(earliest.solved_at) ? current : earliest;
      });

      const initialScore = initialRecord.score;
      const averageScore = (records.reduce((sum, record) => sum + record.score, 0) / records.length).toFixed(2);

      csvRows.push([studentNumber, name, problemSetName, initialScore, averageScore]);
    });

    return csvRows.map(row => row.join(",")).join("\n");
  };

  const handleCSVDownload = () => {
    const csvData = generateCSVData();
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);

    link.setAttribute('href', url);
    link.setAttribute('download', 'students_data.csv');
    link.style.visibility = 'hidden';

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div>
      <div css={style.header}>
        <div css={style.headerTop}>
          <div css={style.logoSection}>
            <div className="title">{selectedProblemSet ? studentData.find((data) => data.problem_set_id === Number(selectedProblemSet))?.problem_set_name : '선택되지 않음'}</div>
            <select css={selectStyles(theme)} onChange={(e) => setSelectedClass(e.target.value)}>
              <option value="">Select a class</option>
              {classes.map((classData) => (
                <option key={classData.id} value={classData.id}>{classData.name}</option>
              ))}
            </select>
            <select css={selectStyles(theme)} onChange={(e) => setSelectedProblemSet(Number(e.target.value))}>
              <option value="">Select a problem set</option>
              {[...new Set(studentData.map((data) => data.problem_set_name))].map((name, index) => (
                <option key={index} value={studentData.find((data) => data.problem_set_name === name).problem_set_id}>
                  {name}
                </option>
              ))}
            </select>
          </div>
          <div css={style.statsSection}>
            <div className="stat">최초제출기준 평균: {statistics.firstSubmissionAvg.toFixed(2)}</div>
            <div className="stat">수정제출포함 평균: {statistics.overallAvg.toFixed(2)}</div>
            <div className="stat">제출학생 수: {statistics.uniqueStudents}</div>
          </div>
        </div>
        <div css={style.headerBottom}>
          <button css={buttonStyles(theme)} className={viewMode === 'chart' ? 'selected' : ''} onClick={() => setViewMode('chart')}>차트 보기</button>
          <button css={buttonStyles(theme)} className={viewMode === 'analysis' ? 'selected' : ''} onClick={() => setViewMode('analysis')}>틀린 문항 분석</button>
          <button onClick={sendvisible} css={style.resultsendButton}>
            결과전송
          </button>
          <button onClick={handleCSVDownload} css={style.csvDownloadButton}>
            CSV 다운로드
          </button>
        </div>
      </div>
      <div css={style.mainContent}>
        {renderContent()}
      </div>
    </div>
  );
};

export default Dashboard;
