import { useState, useEffect } from 'react';
import api from '../utils/Auth'; // 수정된 axios 인스턴스를 import 합니다.

const API_URL = process.env.REACT_APP_API_URL;

const useFetchStudentData = (token, id) => {
  const [data, setData] = useState(null);
  const [initialAnswers, setInitialAnswers] = useState(null);
  const [initialNotSolved, setInitialNotSolved] = useState(null);
  const [problemName, setProblemName] = useState(null);
  const [problemSetId, setProblemSetId] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!id) {
      setError('URL에서 ID 값을 찾을 수 없습니다.');
      setLoading(false);
      return;
    }

    const fetchData = async () => {
      try {
        const response = await api.get(`${API_URL}/students/problem-data`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        if (response.data) {
          const filteredData = response.data.filter(item => item.solved_at === id);
          if (filteredData.length === 0) {
            throw new Error('해당 ID에 대한 데이터를 찾을 수 없습니다.');
          }

          const initialAnswers = filteredData[0].student_answer;
          const problemSetId = filteredData[0].problem_set_id;
          const problemName = filteredData[0].problem_name;
          const initialNotSolved = filteredData[0].is_correct;

          setData(filteredData[0]);
          setInitialAnswers(initialAnswers);
          setInitialNotSolved(initialNotSolved);
          setProblemName(problemName);
          setProblemSetId(problemSetId);
        }
      } catch (error) {
        setError(`사용자 권한 확인 요청 에러: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [token, id]);

  return { data, initialAnswers, initialNotSolved, problemName, problemSetId, error, loading };
};

export default useFetchStudentData;
