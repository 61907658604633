/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useState } from 'react';

const modalOverlayStyle = css`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const modalStyle = css`
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  max-width: 400px;
  width: 100%;
`;

const modalInputStyle = css`
  width: 80%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const modalButtonStyle = css`
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
`;

const ConfirmationModal = ({ onConfirm, onCancel }) => {
  const [inputValue, setInputValue] = useState('');

  const handleConfirm = () => {
    if (inputValue === '제출') {
      onConfirm();
    } else {
      alert('올바른 값을 입력해주세요.');
    }
  };

  return (
    <div css={modalOverlayStyle}>
      <div css={modalStyle}>
        <p>한번 제출하면 재시험을 볼수 없습니다.<br/> 정말 제출하시려면 아래 입력칸에 <strong>'제출'</strong>을 입력해주세요</p>
        <input
          css={modalInputStyle}
          type="text"
          placeholder="제출"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
        />
        <div>
          <button css={modalButtonStyle} onClick={handleConfirm} style={{ marginRight: '10px', backgroundColor: '#98510F', color: 'white' }}>확인</button>
          <button css={modalButtonStyle} onClick={onCancel}>취소</button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
