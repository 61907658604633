
/** @jsxImportSource @emotion/react */

import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import useProblemSets from '../../../hooks/useProblemSets';
import api from '../../../utils/Auth'; // 수정된 axios 인스턴스를 import 합니다.
import { css } from '@emotion/react';
const API_URL = process.env.REACT_APP_API_URL;

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const Problem = ({ theme }) => {
  const { problemSets } = useProblemSets();
  const [pdfUrl, setPdfUrl] = useState('');
  const [numPages, setNumPages] = useState(null);
  const [scale, setScale] = useState(1.0);
  const [clickedButton, setClickedButton] = useState(null);
  const [answers, setAnswers] = useState([]);
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const [selectedGrades, setSelectedGrades] = useState([]);
  const [filteredProblemSets, setFilteredProblemSets] = useState(problemSets);

  useEffect(() => {
    setFilteredProblemSets(problemSets); // problemSets가 변경될 때마다 업데이트
  }, [problemSets]);

  useEffect(() => {
    if (clickedButton) {
      fetchAnswers(clickedButton);
    }
  }, [clickedButton]);

  const fetchAnswers = async (problemId) => {
    try {
      const response = await api.get(`${API_URL}/teachers/problem-sets/${problemId}/answer`);
      setAnswers(response.data);
    } catch (error) {
      console.error('Error fetching answers:', error);
    }
  };

  const handlePdfClick = (problemId) => {
    setPdfUrl(`${API_URL}/teachers/files/problem-sets/${problemId}`);
    setClickedButton(problemId);
  };

  const handleZoomIn = () => {
    setScale(scale + 0.2);
  };

  const handleZoomOut = () => {
    setScale(scale - 0.2);
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handleSubjectChange = (subject) => {
    setSelectedSubjects((prev) =>
      prev.includes(subject) ? prev.filter((s) => s !== subject) : [...prev, subject]
    );
  };

  const handleGradeChange = (grade) => {
    setSelectedGrades((prev) =>
      prev.includes(grade) ? prev.filter((g) => g !== grade) : [...prev, grade]
    );
  };

  const handleFilterApply = () => {
    const filtered = problemSets.filter(problem =>
      (selectedSubjects.length === 0 || selectedSubjects.includes(problem.subject)) &&
      (selectedGrades.length === 0 || selectedGrades.includes(problem.grade))
    );
    setFilteredProblemSets(filtered);
  };

  useEffect(() => {
    handleFilterApply();
  }, [selectedSubjects, selectedGrades, problemSets]);

  return (
    <Container>
      <FilterContainer>
        <FilterSection>
          <h3>과목</h3>
          {["국어", "영어", "수학", "사회", "과학"].map((subject) => (
            <FilterButton
              key={subject}
              selected={selectedSubjects.includes(subject)}
              theme={theme}
              onClick={() => handleSubjectChange(subject)}
            >
              {subject}
            </FilterButton>
          ))}
        </FilterSection>
        <FilterSection>
          <h3>학년</h3>
          {["초등학교 1학년", "초등학교 2학년", "초등학교 3학년", "초등학교 4학년", "초등학교 5학년", "초등학교 6학년"].map((grade) => (
            <FilterButton
              key={grade}
              selected={selectedGrades.includes(grade)}
              theme={theme}
              onClick={() => handleGradeChange(grade)}
            >
              {grade}
            </FilterButton>
          ))}
        </FilterSection>
      </FilterContainer>
      <Content>
        <Sidebar>
          {filteredProblemSets.length > 0 ? (
            filteredProblemSets.map((problem) => (
              <ProblemButton
                key={problem.problem_set_id}
                clicked={clickedButton === problem.problem_set_id}
                theme={theme}
                onClick={() => handlePdfClick(problem.problem_set_id)}
              >
                {problem.problem_set_name}
              </ProblemButton>
            ))
          ) : (
            <ProblemButton theme={theme}>해당 문제가 없습니다</ProblemButton>
          )}
        </Sidebar>
        <PdfViewerContainer>
          <ZoomControls>
            <button css={zoomButtonStyles} onClick={handleZoomIn}>+</button>
            <button css={zoomButtonStyles} onClick={handleZoomOut}>-</button>
          </ZoomControls>
          {pdfUrl && (
            <Document
              file={{
                url: pdfUrl,
                httpHeaders: {
                  'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
                },
              }}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              {Array.from(new Array(numPages), (el, index) => (
                <Page key={`page_${index + 1}`} pageNumber={index + 1} scale={scale} />
              ))}
            </Document>
          )}
        </PdfViewerContainer>
        <MainContent>
          <Table>
            <thead>
              <tr>
                <th>문항번호</th>
                <th>정답</th>
              </tr>
            </thead>
            <tbody>
              {answers.map((answer) => (
                <tr key={answer.problem_id}>
                  <td>{answer.problem_id}</td>
                  <td>{answer.answer}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </MainContent>
      </Content>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const FilterContainer = styled.div`
  display: flex;
  padding: 10px;
`;

const FilterSection = styled.div`
  margin-right: 20px;
`;

const FilterButton = styled.button`
  margin: 5px;
  padding: 10px 20px;
  background-color: ${({ selected, theme }) => (selected ? theme.colors.brandColor4 : '#f0f0f0')};
  color: ${({ selected }) => (selected ? 'white' : 'black')};
  border: none;
  cursor: pointer;
  border-radius: 10px;
  transition: background-color 0.3s;
  &:hover {
    background-color: ${({ selected, theme }) => (selected ? theme.colors.brandColor4 : '#e0e0e0')};
  }
`;

const zoomButtonStyles = css`
  background: #007bff;
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  cursor: pointer;
  transition: background 0.3s, transform 0.2s;

  &:hover {
    background: #0056b3;
  }

  &:active {
    transform: scale(0.95);
  }
`;



const Content = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
`;

const Sidebar = styled.div`
  flex: 1.5;
  padding: 20px;
  background-color: #f4f4f4;
  overflow-y: auto;
`;

const MainContent = styled.div`
  flex: 3.5;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PdfViewerContainer = styled.div`
  flex: 5;
  padding: 20px;
  box-sizing: border-box;
  height: 100vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

const ZoomControls = styled.div`
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  width: 100%;
`;




const ProblemButton = styled.button`
  display: block;
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  background-color: ${({ clicked, theme }) => (clicked ? theme.colors.brandColor4 : 'transparent')};
  color: ${({ clicked, theme }) => (clicked ? 'white' : theme.colors.buttonText)};
  border: none;
  cursor: pointer;
  text-align: left;
  transition: background-color 0.3s;
  &:hover {
    background-color: rgba(0, 123, 255, 0.1);
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;

  th, td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
  }

  th {
    background-color: #4CAF50;
    color: white;
  }
`;

export default Problem;
