/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { css, useTheme } from '@emotion/react';
import StudentsRigist from './StudentsRigist';
import Modal from 'react-modal';
import useClasses from '../../hooks/useClasses';
import buttonStyles from '../../style/buttonStyles';

const styles = (theme) => ({
  resetButton: css`
    all: unset;
    button {
      all: unset;
    }
  `,
  header: css`
    width: 100%;
    color: black;
    display: flex;
    flex-direction: column;
    padding: 10px 20px;
    margin-top: 30px;
  `,
  headerTop: css`
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  `,
  logoSection: css`
    display: flex;
    align-items: center;
    .logo {
      width: 40px;
      height: 40px;
      margin-right: 10px;
    }
    .title {
      font-size: 24px;
      font-weight: bold;
      margin-right: 20px;
    }
  `,
  statsSection: css`
    display: flex;
    align-items: center;
    margin-top: 10px;
    .stat {
      margin-right: 20px;
      font-size: 16px;
      color: #666;
    }
  `,
  headerBottom: css`
    display: flex;
    margin-top: 10px;
    align-items: center;
    justify-content: flex-start;
    .button {
    }
    .selected {
      background-color: #d5d5d5;
    }
  `,
  button: css`
    margin-right: 10px;
    padding: 10px 20px;
    border: none;
    background-color: ${theme.colors.brandColor4};
    color: white;
    cursor: pointer;
    border-radius: 5px;
    &:hover {
      background-color: #e5e5e5;
    }
  `,
  mainContent: css`
    padding-left: 20px;
  `,
  chartContainer: css`
    margin-top: 20px;
  `,
  modalContent: css`
    display: flex;
    flex-direction: column;
    padding: 20px;
    background: white;
    width: 80%;
    border-radius: 10px;
    margin: auto;
    height: 400px; /* Set fixed height */
    overflow-y: auto; /* Enable vertical scrolling */
  `,
  modalInput: css`
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
  `,
  modalButton: css`
    padding: 10px 20px;
    background-color: ${theme.colors.brandColor4}; /* Applied theme color */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
      background-color: ${theme.colors.brandColor4}; /* Applied theme color */
    }
  `,
  toggleContainer: css`
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  `,
  activeToggle: css`
    background-color: rgba(0, 0, 0, 0.1);
    width: 50%;
    height: 40px;
    color: black;
    border: none;
    font-size: 18px;
    font-weight: bold;
    margin: 0 10px;
    cursor: pointer;
    border-radius: 25px;
  `,
  inactiveToggle: css`
    background-color: rgba(0, 0, 0, 0);
    width: 50%;
    color: #a4b0c1;
    height: 40px;
    border: none;
    font-weight: bold;
    font-size: 18px;
    margin: 0 10px;
    cursor: pointer;
    border-radius: 25px;
    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
  `,
  agreementSection: css`
    margin-bottom: 20px;
  `,
  agreementText: css`
    font-size: 14px;
    margin-bottom: 10px;
  `,
  agreementCheckbox: css`
    display: flex;
    align-items: center;
    input {
      margin-right: 10px;
    }
  `,
  errorText: css`
    color: red;
    margin-top: 10px;
  `
});

const modalStyles = {
  content: {
    width: '30%',
    margin: 'auto',
    borderRadius: '10px',
  },
};

const Students = () => {
  const [viewMode, setViewMode] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newClassName, setNewClassName] = useState('');
  const [joinCode, setJoinCode] = useState('');
  const [modalMode, setModalMode] = useState('new'); // 'new' 또는 'join'
  const [selectedClass, setSelectedClass] = useState(null);
  const [isAgreementChecked, setIsAgreementChecked] = useState(false);
  const [error, setError] = useState('');

  const { classes, addClass, joinClass } = useClasses();
  const theme = useTheme();
  const style = styles(theme);

  const handleAddClass = async () => {
    if (!isAgreementChecked) {
      setError('동의하지 않을 시 학급 개설이 제한됩니다.');
      return;
    }
    await addClass(newClassName);
    setNewClassName('');
    setIsModalOpen(false);
    setIsAgreementChecked(false);
    setError('');
  };

  const handleJoinClass = async () => {
    if (!isAgreementChecked) {
      setError('동의하지 않을 시 학급 개설이 제한됩니다.');
      return;
    }
    await joinClass(joinCode);
    setJoinCode('');
    setIsModalOpen(false);
    setIsAgreementChecked(false);
    setError('');
  };

  const handleClassButtonClick = (classData) => {
    setViewMode('rigist');
    setSelectedClass(classData);
  };

  const renderModalContent = () => {
    return (
      <>
        <div css={style.agreementSection}>
          <div css={style.agreementText}>
          [개인정보처리업무 위탁에 관한 사항]<br/>
            수탁자: 이영호<br/>
            수탁하는 업무의 목적 및 범위: 코코아교실 연구 서비스 제공<br/>
            수탁 기간: 연구 종료 시까지<br/>
            수탁자는 개인정보 업무 위탁에 따른 목적 범위를 넘어 개인정보를 처리하지 않으며, 수탁 목적 범위를 넘어 처리되는 정보의 처리에 대해서는 정보주체로부터 별도의 동의를 받아 처리합니다.<br/>
            수탁자는 「개인정보의 안전성 확보조치 기준」(개인정보보호위원회 고시)에 따라 안전하게 개인정보를 처리하고, 보호합니다.<br/><br/>

            1. 개인정보의 수집 및 이용 목적<br/>
            - 시험 응시 및 관리: 교사가 제공한 아이디를 통해 학생이 시험에 응시할 수 있도록 하기 위함.<br/>
            - 성적 처리 및 분석: 학생의 시험 결과를 처리하고 분석하여 성적을 부여하기 위함.<br/>
            - 질문 및 답변 관리: AI 보조교사에게 학생이 질문한 내용을 저장하여 향후 학습 자료로 활용하기 위함.<br/>
            - 학술 연구: 수집된 데이터를 익명화하여 교육 및 인공지능 분야의 학술 연구에 활용하기 위함.<br/><br/>

            2. 수집하는 개인정보의 항목 <br/>
            교사가 제공한 학생 아이디<br/>
            아이디별 시험 응시 기록, 성적 정보, AI 보조교사에게 질문한 내용 및 이에 대한 답변<br/><br/>

            3. 개인정보의 보유 및 이용 기간 보유 기간<br/>
            개인정보는 수집 및 이용 목적 달성 시까지 보유하며, 관련 법령에 따라 일정 기간 보관 후 안전하게 파기함<br/>
          </div>
          <div css={style.agreementCheckbox}>
            <input
              type="checkbox"
              checked={isAgreementChecked}
              onChange={() => setIsAgreementChecked(!isAgreementChecked)}
            />
            <label>개인정보 위탁 동의</label>
          </div>
          {error && <div css={style.errorText}>{error}</div>}
        </div>
        {modalMode === 'new' ? (
          <>
            <h2>학급 이름 등록</h2>
            <input
              css={style.modalInput}
              type="text"
              value={newClassName}
              onChange={(e) => setNewClassName(e.target.value)}
              placeholder="학급 이름 입력"
            />
            <button css={style.modalButton} onClick={handleAddClass}>확인</button>
          </>
        ) : (
          <>
            <h2>코드로 등록</h2>
            <input
              css={style.modalInput}
              type="text"
              value={joinCode}
              onChange={(e) => setJoinCode(e.target.value)}
              placeholder="6자리 코드 입력"
            />
            <button css={style.modalButton} onClick={handleJoinClass}>등록</button>
          </>
        )}
      </>
    );
  };

  const renderContent = () => {
    switch (viewMode) {
      case 'rigist':
        return (
          <div css={style.chartContainer}>
            <StudentsRigist classData={selectedClass} selectedClass={selectedClass} />
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div>
      <div css={style.header}>
        <div css={style.headerTop}></div>
        <div css={style.headerBottom}>
          {classes.map((classData) => (
            <button
              key={classData.id}
              css={buttonStyles(theme)}
              className={viewMode === 'rigist' && selectedClass?.name === classData.name ? 'selected' : ''}
              onClick={() => handleClassButtonClick(classData)}
            >
              {classData.name}
            </button>
          ))}
          <button css={style.button} onClick={() => setIsModalOpen(true)}>+ 학급 등록</button>
        </div>
      </div>
      <div css={style.mainContent}>
        {renderContent()}
      </div>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        contentLabel="Add Class Modal"
        style={modalStyles}
      >
        <div css={style.modalContent}>
          <div css={style.toggleContainer}>
            <button
              css={modalMode === 'new' ? style.activeToggle : style.inactiveToggle}
              onClick={() => setModalMode('new')}
            >
              신규등록
            </button>
            <button
              css={modalMode === 'join' ? style.activeToggle : style.inactiveToggle}
              onClick={() => setModalMode('join')}
            >
              코드로 등록
            </button>
          </div>
          {renderModalContent()}
        </div>
      </Modal>
    </div>
  );
};

export default Students;
