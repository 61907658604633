/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

const selectStyles = (theme) => css`
appearance: none;
-webkit-appearance: none;
-moz-appearance: none;
background: #f5f5f5;
border: none;
border-radius: 10px;
padding: 8px 25px 8px 12px; /* Adjusted padding for left margin */
font-size: 14px; /* Reduced font size */
font-family: ${theme.fonts.main}; /* Applied main font */
color: #333;
cursor: pointer;
outline: none;
background-image: url('data:image/svg+xml;charset=US-ASCII,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"><path fill="gray" d="M7 10l5 5 5-5z"/></svg>');
background-repeat: no-repeat;
background-position: right 10px center;
background-size: 16px 16px;
margin-right: 15px; /* Left margin adjustment */
min-width: 120px; /* Adjusted width */
`;

export default selectStyles;
