/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Sidebar from './Sidebar';
import MainContent from './StudentCon';
import { css, useTheme } from '@emotion/react';
import useAuth from '../hooks/useAuth';


const API_URL = process.env.REACT_APP_API_URL;

const styles = (theme) => ({
  teachermain: css`
    width: 100%;
    height: 100vh;
  `,
});

const StudentMain = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedSection, setSelectedSection] = useState('questions');
  const [isCollapsed, setIsCollapsed] = useState(false);
  const theme = useTheme();
  const { isLoggedIn, userName, setIsLoggedIn } = useAuth();

  const style = styles(theme);


  
  useEffect(() => {
    const currentPath = location.pathname.split('/')[1];
    if (currentPath) {
      setSelectedSection(currentPath);
    } else {
      navigate('/studentexam');
    }
  }, [location, navigate]);

  if (userName && userName !== 'Student') {
    navigate('/');
    return null;
  }

  const handleSelect = (section) => {
    setSelectedSection(section);
    navigate(`/${section}`);
  };

  return (
    <div css={style.teachermain}>
      <Sidebar
        onSelect={handleSelect}
        setIsLoggedIn={setIsLoggedIn}
        isCollapsed={isCollapsed}
        setIsCollapsed={setIsCollapsed}
        selectedSection={selectedSection}  // Sidebar로 selectedSection 전달
        css={style.sidebar}
      />
      <MainContent selectedSection={selectedSection} css={style.maincontent} />
    </div>
  );
};

export default StudentMain;
