/** @jsxImportSource @emotion/react */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/logo.png';
import { css } from '@emotion/react';

const styles = {
  header: css`
    background-color: white;
    height: 60px !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
  `,
  logoContainer: css`
    display: flex;
    cursor: pointer;
    align-items: center;
    .logo {
      width: 90px;
      height: 30px;
    }
    p {
      margin-left: 10px;
      font-size: 16px;
    }
  `,
  logoutButton: css`
    background: none;
    border: none;
    color: #197ed7;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 14px;
    &:hover {
      opacity: 0.8;
    }
  `
};

const Header = ({ isLoggedIn }) => {
  const navigate = useNavigate();

  const logout = () => {
    localStorage.removeItem('access_token');
    navigate(`/`);
  };

  return (
    <div css={styles.header}>
      <div css={styles.logoContainer} onClick={() => navigate('/')}>
        <img src={logo} alt="Logo" className="logo" />
      </div>
      {isLoggedIn && (
        <button onClick={logout} css={styles.logoutButton}>
          로그아웃
        </button>
      )}
    </div>
  );
};

export default Header;
