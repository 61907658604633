import React, { useState } from 'react';
import styled from '@emotion/styled';

const FilterModal = ({ isOpen, onClose, onApply, theme }) => {
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const [selectedGrades, setSelectedGrades] = useState([]);

  const subjects = ["국어", "영어", "수학", "사회", "과학"];
  const grades = ["초등학교 1학년", "초등학교 2학년", "초등학교 3학년", "초등학교 4학년", "초등학교 5학년", "초등학교 6학년"];

  const handleSubjectChange = (subject) => {
    setSelectedSubjects((prev) =>
      prev.includes(subject) ? prev.filter((s) => s !== subject) : [...prev, subject]
    );
  };

  const handleGradeChange = (grade) => {
    setSelectedGrades((prev) =>
      prev.includes(grade) ? prev.filter((g) => g !== grade) : [...prev, grade]
    );
  };

  const handleApply = () => {
    onApply(selectedSubjects, selectedGrades);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <ModalOverlay>
      <ModalContent>
        <h2>필터 설정</h2>
        <FilterSection>
          <h3>과목</h3>
          {subjects.map((subject) => (
            <FilterButton
              key={subject}
              selected={selectedSubjects.includes(subject)}
              theme={theme}
              onClick={() => handleSubjectChange(subject)}
            >
              {subject}
            </FilterButton>
          ))}
        </FilterSection>
        <FilterSection>
          <h3>학년</h3>
          {grades.map((grade) => (
            <FilterButton
              key={grade}
              selected={selectedGrades.includes(grade)}
              theme={theme}
              onClick={() => handleGradeChange(grade)}
            >
              {grade}
            </FilterButton>
          ))}
        </FilterSection>
        <ButtonContainer>
          <CancleButton onClick={onClose} theme={theme}>취소</CancleButton>
          <StyledButton onClick={handleApply} theme={theme}>적용</StyledButton>
        </ButtonContainer>
      </ModalContent>
    </ModalOverlay>
  );
};

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
`;

const FilterSection = styled.div`
  margin-bottom: 20px;
`;

const FilterButton = styled.button`
  margin: 5px;
  padding: 10px 20px;
  background-color: ${({ selected, theme }) => (selected ? theme.colors.brandColor4 : '#f0f0f0')};
  color: ${({ selected }) => (selected ? 'white' : 'black')};
  border: none;
  cursor: pointer;
  border-radius:10px;
  transition: background-color 0.3s;
  &:hover {
    background-color: ${({ selected, theme }) => (selected ? theme.colors.brandColor4 : '#e0e0e0')};
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;

const StyledButton = styled.button`
  padding: 10px 20px;
  background-color: ${({ theme }) => theme.colors.brandColor4};
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
  &:hover {
    background-color: #0056b3;
  }
`;

const CancleButton =styled.button`
padding: 10px 20px;
background-color: white;
color: black;
transition: background-color 0.3s;
cursor: pointer;
&:hover {
  background-color: #0056b3;
}
`;

export default FilterModal;
