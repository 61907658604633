/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { css, useTheme } from '@emotion/react';
import Problem from './Problem';
import buttonStyles from '../../../style/buttonStyles';
const styles = (theme) => ({
  resetButton: css`
    all: unset;
    button {
      all: unset;
    }
  `,
  header: css`
    width: 100%;
    color: black;
    display: flex;
    flex-direction: column;
    padding: 10px 20px;
    margin-top: 30px;
  `,
  headerTop: css`
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  `,
  logoSection: css`
    display: flex;
    align-items: center;
    .logo {
      width: 40px;
      height: 40px;
      margin-right: 10px;
    }
    .title {
      font-size: 24px;
      font-weight: bold;
      margin-right: 20px;
    }
  `,
  statsSection: css`
    display: flex;
    align-items: center;
    margin-top: 10px;
    .stat {
      margin-right: 20px;
      font-size: 16px;
      color: #666;
    }
  `,
  headerBottom: css`
    display: flex;
    margin-top: 10px;
    align-items: center;
    justify-content: flex-start;
  `,
  mainContent: css`
    padding-left: 20px;
  `,
  chartContainer: css`
    margin-top: 20px;
  `,
});

const Questions = () => {
  const [viewMode, setViewMode] = useState('myproblem');
  const theme = useTheme();
  const style = styles(theme);

  const renderContent = () => {
    switch (viewMode) {
    //  case 'rigist':
    //    return <div css={style.chartContainer}><Rigist /></div>;
      case 'myproblem':
        return <div css={style.chartContainer}><Problem /></div>;
 
 
      default:
        return <div css={style.chartContainer}><Problem /></div>;
    }
  };

  return (
    <div>
      <div css={style.header}>
        <div css={style.headerTop}></div>
        <div css={style.headerBottom}>
         {/* <button css={buttonStyles(theme)} className={viewMode === 'rigist' ? 'selected' : ''} onClick={() => setViewMode('rigist')}>문항 등록</button> */}
         <button css={buttonStyles(theme)} className={viewMode === 'myproblem' ? 'selected' : ''} onClick={() => setViewMode('myproblem')}>문항 관리</button>

        </div>
      </div>
      <div css={style.mainContent}>
        {renderContent()}
      </div>
    </div>
  );
};

export default Questions;
