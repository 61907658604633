/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useCallback } from 'react';

const answerInputStyle = css`
  width: 90%;
  display: flex;
  margin-bottom: 10px;
`;

const answerLabelStyle = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 10px;
`;

const textareaStyle = css`
  width: 100%;
  height: 25px;
  resize: none;
  overflow: hidden;
  font-size: 15px;
`;

const AnswerInput = ({ id, index, value, handleAnswerChange }) => {
  const adjustTextareaHeight = useCallback((element) => {
    element.style.height = 'auto';
    element.style.height = element.scrollHeight + 'px';
  }, []);

  const onChange = useCallback((event) => {
    handleAnswerChange(event, index);
    adjustTextareaHeight(event.target);
  }, [handleAnswerChange, index, adjustTextareaHeight, id]);

  return (
    <div key={id} css={answerInputStyle}>
      <div css={answerLabelStyle}>
        <p>{id}</p> <p>번</p>
      </div>
      <textarea
        placeholder={`정답을 입력해주세요`}
        value={value}
        onChange={onChange}
        css={textareaStyle}
      />
    </div>
  );
};

export default AnswerInput;
