/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Sidebar from './Sidebar';
import MainContent from './TeacherCon';
import { css, useTheme } from '@emotion/react';
import useAuth from '../hooks/useAuth';

const styles = (theme) => ({
  teachermain: css`
    width: 100%;
    height: 100vh;
  `,
});

const TeacherMain = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedSection, setSelectedSection] = useState('');
  const [isCollapsed, setIsCollapsed] = useState(false);
  const theme = useTheme();
  const style = styles(theme);
  const { isLoggedIn, userName, setIsLoggedIn } = useAuth();

  useEffect(() => {
    const currentPath = location.pathname.split('/')[1];
    if (currentPath) {
      setSelectedSection(currentPath);
    } else {
      navigate('/prompts');
    }
  }, [location, navigate]);

  const handleSelect = (section) => {
    setSelectedSection(section);
    navigate(`/${section}`);
  };

  if (userName && userName !== 'Teacher') {
    navigate('/');
    return null;
  }

  return (
    <div css={style.teachermain}>
      {selectedSection !== 'exam' && (
        <Sidebar
          onSelect={handleSelect}
          setIsLoggedIn={setIsLoggedIn}
          isCollapsed={isCollapsed}
          setIsCollapsed={setIsCollapsed}
          selectedSection={selectedSection}  // Sidebar로 selectedSection 전달
          css={style.sidebar}
        />
      )}
      <MainContent selectedSection={selectedSection} css={style.maincontent} />
    </div>
  );
};

export default TeacherMain;
