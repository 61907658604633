/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { css } from '@emotion/react';
import api from '../utils/Auth'; // 수정된 axios 인스턴스를 import 합니다.

const API_URL = process.env.REACT_APP_API_URL;

const StudentExam = ({ userName }) => {
  const navigate = useNavigate();
  const [examStatus, setExamStatus] = useState('loading');
  const [problemSetId, setProblemSetId] = useState('');

  useEffect(() => {
    const fetchVisibleProblems = async () => {
      try {
        const response = await api.get(`${API_URL}/students/problem-sets/test`);
        if (response.data) {
          setExamStatus('available');
          setProblemSetId(response.data.problem_set_id);
        } else {
          setExamStatus('notAvailable');
        }
      } catch (error) {
        if (error.response && error.response.status === 409 && error.response.data.detail === "Student Already Submitted") {
          setExamStatus('alreadySubmitted');
        } else {
          console.error('사용자 권한 확인 요청 에러:', error);
          setExamStatus('notAvailable');
        }
      }
    };

    fetchVisibleProblems();
  }, [navigate]);

  const handleClick = () => {
    if (examStatus === 'available') {
      navigate(`/page/${problemSetId}`);
    }
  };

  return (
    <div css={styles.teacherContent}>
      <div css={styles.baggeheader}>
        <button
          css={[
            styles.badgeButton, 
            examStatus !== 'available' && styles.disabledBadgeButton
          ]}
          onMouseEnter={(e) => examStatus === 'available' && (e.target.style.transform = 'scale(1.05)')}
          onMouseLeave={(e) => examStatus === 'available' && (e.target.style.transform = 'scale(1)')}
          onClick={handleClick}
          disabled={examStatus !== 'available'}
        >
          {examStatus === 'available' ? '시험 입장' : 
           examStatus === 'alreadySubmitted' ? '이미 제출한 시험' : 
           '시험 시작 전'}
        </button>
      </div>
    </div>
  );
};

const styles = {
  menutext: css`
    font-size: 15px;
  `,
  teacherContent: css`
    width: 100%;
    height: 100vh; /* Ensure it takes full viewport height */
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  headerText: css`
    font-size: 25px;
    color: black;
  `,
  wrappingchart: css`
    width: 50%;
  `,
  badgeText: css`
    margin-top: 20px;
  `,
  badgeButton: css`
    background: linear-gradient(135deg, #2caec6 0%, #3fd5e3 100%);
    color: white;
    border-radius: 50%;
    border: 3px solid #d9e6ec;
    width: 200px;
    height: 200px;
    font-size: 24px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    margin-top: 30px;
    @media (max-width: 768px) {
      width: 80px;
      height: 80px;
      font-size: 14px;
    }
  `,
  disabledBadgeButton: css`
    background: gray;
    cursor: not-allowed;
  `,
  mainlogo: css`
    width: 10%;
    @media (max-width: 768px) {
      width: 30%;
    }
  `,
  startContent: css`
    text-align: center;
    margin: 20px 0;
  `,
  welcomeMessage: css`
    font-size: 24px;
    font-weight: bold;
  `,
  menuZone: css`
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 40px;
    margin-top: 10%;
    @media (max-width: 768px) {
      margin-top: 30%;
    }
  `,
  menuButton: css`
    -webkit-appearance: none;
    background-color: white;
    border: none;
    font-size: 20px;
  `,
  contentZone: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: 10%;
    padding-left: 10%;
  `,
  upzone: css`
    text-align: center;
    margin-bottom: 20px;
  `,
  button: css`
    width: 200px;
    padding: 10px;
    margin: 10px;
    text-align: center;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s;
  `,
  disabled: css`
    background-color: #ccc;
    cursor: not-allowed;
  `,
  downzone: css`
    width: 100%;
    overflow-y: auto;
  `,
  problemSetButtons: css`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 10px 0;
  `,
  problemSetButton: css`
    padding: 10px 20px;
    margin: 5px;
    font-size: 16px;
    cursor: pointer;
  `,
  active: css`
    background-color: #007bff;
    color: white;
  `,
  buttonContainer: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 100%;
  `,
  buttonStyle: css`
    padding: 10px 15px;
    width: 80%;
    background-color: #00d0ff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    height: 60px;
  `,
  firstOrder: css`
    background-color: blue;
    color: white;
  `,
  otherOrder: css`
    background-color: green;
    color: white;
  `,
};

export default StudentExam;
