import React from 'react';
import { Routes, Route, Link,Navigate } from 'react-router-dom';
import Main from './Mainpage';
import Questionsolve from './Student/questionsolve/index';
import Solvepage from './Student/llmsolvepage/index';
import TeacherMain from './Teacher/TeacherMain';
import StudentMain from './Student/StudentMain';
import PrivacyPolicy from './Privacy';
import TermsOfService from './TermsOfService'

function App() {

  return(
  <Routes> {/* 라우트 설정 */}
      <Route path="/" element={<Main/>} />
      <Route path="/privacypolicy" element={<PrivacyPolicy/>} />
      <Route path="/termsofservice" element={<TermsOfService/>} />
      <Route path="/" element={<Navigate to="/prompts" replace />} />
      <Route path="/" element={<Navigate to="/studentexam" replace />} />
      <Route path="/studentexam" element={<StudentMain />} />
      <Route path="/incorrectnotes" element={<StudentMain />} />
      <Route path="/questions" element={<TeacherMain />} />
      <Route path="/exam" element={<TeacherMain />} />
      <Route path="/results" element={<TeacherMain />} />
      <Route path="/prompts" element={<TeacherMain />} />
      <Route path="/data" element={<TeacherMain />} />
      <Route path="/students" element={<TeacherMain />} />
      <Route path="/page/:id" element={<Questionsolve />} />
      <Route path="/solve/:id" element={<Solvepage />} />
      <Route path="*" element={<Navigate to="/" replace />} />



  </Routes>
  )
}

export default App;
