/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Chat from './Chat';
import ProblemSelector from './ProblemSelector';
import AnswerForm from './AnswerForm';
import { ClipLoader } from 'react-spinners';
import useFetchStudentData from '../../hooks/useFetchStudentData'; // 커스텀 훅 import
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import api from '../../utils/Auth'; // 수정된 axios 인스턴스를 import 합니다.
import ConfirmationModal from './ConfirmationModal';

const API_URL = process.env.REACT_APP_API_URL;

const LeftPage = () => {
  const [currentProblem, setCurrentProblem] = useState(1);
  const [problemMessages, setProblemMessages] = useState([]);
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [answers, setAnswers] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [evaluation, setEvaluation] = useState('');

  const navigate = useNavigate();
  const { id } = useParams();

  const token = localStorage.getItem('access_token');
  const { data, initialNotSolved, initialAnswers, problemSetId, error, loading } = useFetchStudentData(token, id);

  useEffect(() => {
    if (data) {
      setProblemMessages(Array(initialNotSolved.length).fill([]));
      setAnswers(initialAnswers);
    }
  }, [data]);

  const handleProblemSelect = useCallback((problemNumber) => {
    setCurrentProblem(problemNumber);
    setMessages(problemMessages[problemNumber - 1]);
  }, [problemMessages]);

  const handleAnswerChange = useCallback((newAnswer, index) => {
    setAnswers((prev) => ({ ...prev, [index]: newAnswer }));
  }, []);

  const handleSubmitAnswer = async () => {
    if (!evaluation) {
      alert('설문 조사를 완료해주세요.');
      return;
    }

    const answersArray = Object.values(answers);
    try {
      setIsSubmitting(true);
      closeModal(); // Close the modal after successful submission

      const response = await api.post(`${API_URL}/students/problem-sets/grade`, {
        problem_set_id: problemSetId,
        student_answer: answersArray,
        is_reassessment: initialNotSolved,
        student_evaluation: evaluation,
      });
      alert('정답이 제출되었습니다!');
      navigate('/');
    } catch (error) {
      if (error.response && error.response.status === 403) {
        if (error.response.data.detail === "Student Already Submitted Revisions Twice") {
          alert('수정 가능 횟수를 모두 사용하였습니다');
        } else {
          alert('수정 제출에 실패했습니다. 다시 시도해주세요.');
        }
      } else {
        console.error('정답 제출 에러:', error);
        alert('시험중에는 수정할수 없습니다. 시험이 끝난 후. 다시 시도해주세요.');
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleEvaluationChange = (event) => {
    setEvaluation(event.target.value);
  };

  if (loading) {
    return <ClipLoader color="#ffffff" size={150} />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div css={styles.app}>
      <div css={styles.solvecontent}>
        {isSubmitting && (
          <div css={styles.loadingOverlay}>
            <ClipLoader color="#ffffff" size={150} />
          </div>
        )}
        <div css={styles.leftPane}>
          <ProblemSelector
            currentProblem={currentProblem}
            notSolved={initialNotSolved}
            handleProblemSelect={handleProblemSelect}
          />
          <AnswerForm
            currentProblem={currentProblem}
            initialAnswers={answers}
            notSolved={initialNotSolved}
            handleAnswerChange={handleAnswerChange}
          />
          <Chat
            currentProblem={currentProblem}
            problemMessages={problemMessages}
            setProblemMessages={setProblemMessages}
            messages={messages}
            setMessages={setMessages}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
            solvedata={data}
          />
          <div css={styles.submitContainer}>
            <p>정답은 모든 문항을 수정 후 한 번만 눌러주세요!</p>
            <button onClick={openModal} css={styles.submitButton}>
              수정된 정답 제출
            </button>
          </div>
        </div>
      </div>
      <ConfirmationModal
        isOpen={isModalOpen}
        onClose={closeModal}
        onSubmit={handleSubmitAnswer}
        handleEvaluationChange={handleEvaluationChange}
        evaluation={evaluation}
      />
    </div>
  );
};

export default LeftPage;

const styles = {
  app: css`
    display: flex;
    flex-direction: column;
    height: 100vh;
  `,
  solvecontent: css`
    display: flex;
    height: calc(100vh - 50px); /* Adjust for header height if needed */
  `,
  leftPane: css`
    width: 80%;
    padding: 20px;
    padding-left: 5%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  `,
  submitButton: css`
    width: 100%;
    margin-top: 10px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #98510f;
    color: white;
    cursor: pointer;
  `,
  loadingOverlay: css`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  `,
};
