import { useState, useEffect } from 'react';
import api from '../utils/Auth'; // 수정된 axios 인스턴스를 import 합니다.
import { mapStudentProblemData } from '../utils/dataMapper';

const API_URL = process.env.REACT_APP_API_URL;

const useStudentProblemData = (selectedClass) => {
  const [studentData, setStudentData] = useState([]);
  const [error, setError] = useState(null);

  const fetchData = async () => {

    if (!selectedClass) return;

    try {
      const response = await api.get(`${API_URL}/teachers/classrooms/${selectedClass}/students/problem-data`);
      if (response.data) {
        const mappedData = mapStudentProblemData(response.data);
        setStudentData(mappedData);
      }
    } catch (error) {
      console.error('Error fetching student data:', error);
      setError(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [selectedClass]);

  return { studentData, error, fetchData };
};

export default useStudentProblemData;
