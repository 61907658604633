import React from 'react';

const ProblemSelector = ({ currentProblem, notSolved, handleProblemSelect }) => {
  
  return (
    <div style={styles.container}>
      {Array.from({ length: notSolved.length }, (_, i) => (
        <button key={i}
          onClick={() => handleProblemSelect(i + 1)}
          style={{
            ...styles.button,
            background: currentProblem === i + 1 ? '#007bff' : '#f8f9fa',
            color: notSolved[i] ? 'black' : 'red',
          }}>
          <div>{i + 1}</div><div>번</div>
        </button>
      ))}
    </div>
  );
};

const styles = {
  container: {
    width: '80%',
    display: 'grid',
    gridTemplateColumns: 'repeat(10, 1fr)',
    gap: '5px',
  },
  button: {
    border: 'none',
    display: 'flex',
    borderRadius: '5px',
    cursor: 'pointer',
    height: '30px',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

export default ProblemSelector;
