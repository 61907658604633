/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

const buttonStyles = (theme) => css`
  margin-right: 10px;
  padding: 10px 20px;
  border: none;
  background-color: transparent;
  color: ${theme.colors.buttonText};
  cursor: pointer;
  border-radius: 5px;
  font-family: ${theme.fonts.main};
  font-weight: 700; /* 버튼의 폰트 웨이트 설정 */
  &:hover {
    background-color: ${theme.colors.buttonSelectedBg};
    color: ${theme.colors.buttonSelectedText};
  }
  &.selected {
    background-color: ${theme.colors.buttonSelectedBg};
    color: ${theme.colors.buttonSelectedText};
  }
`;

export default buttonStyles;
