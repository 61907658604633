/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/logo.png';
import { css, useTheme } from '@emotion/react';

const styles = (theme) => ({
  header: css`
    background-color: ${theme.colors.sidebar};
    color: rgb(122, 144, 169);
    display: flex;
    align-items: center;
    padding: 10px 20px;
    transition: all 0.3s;
    .logo-container {
      display: flex;
      align-items: center;
      margin-right: auto;
      .logo {
        width: 90px;
        height: 30px;
        margin-right: 10px;
      }
      .brand-name {
        color: ${theme.colors.fontcolor};
      }
    }
    .menu {
      display: flex;
      align-items: center;
      button {
        display: flex;
        align-items: center;
        padding: 10px;
        font-size: 15px;
        background: none;
        border: none;
        color: ${theme.colors.buttonText};
        font-family: ${theme.fonts.main};
        font-weight: 700;
        margin: 0 10px;
        cursor: pointer;
        &:hover {
          background-color: ${theme.colors.buttonSelectedBg};
        }
        &.selected {
          color: black;
        }
        .beta-tag {
          border: 1px solid red;
          margin-left: 5px;
          padding: 2px 5px;
          font-size: 12px;
          background-color: ${theme.colors.betaBackground};
          color: red;
          border-radius: 3px;
        }
      }
    }
    .logout-container {
      margin-left: auto;
      .logout-button {
        background: none;
        border: none;
        color: #ff4b5c;
        padding: 10px;
        cursor: pointer;
        font-size: 14px;
        font-weight: bold;
        &:hover {
          background-color: #ff4b5c;
          color: white;
        }
      }
    }
  `,
  startExamButton: css`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 15px;
    font-size: 16px;
    background-color: ${theme.colors.brandColor4};
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 0 10px;
    &:hover {
      background-color: ${theme.colors.primaryHover};
    }
  `,
});

const Header = ({ onSelect, setIsLoggedIn, selectedSection }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const style = styles(theme);
  const [selectedMenu, setSelectedMenu] = useState('');

  useEffect(() => {
    setSelectedMenu(selectedSection);
  }, [selectedSection]);

  const handleMenuClick = (section) => {
    setSelectedMenu(section);
    if (section === 'start-exam') {
      openExamWindow();
    } else {
      onSelect(section);
    }
  };

  const openExamWindow = () => {
    const url = '/exam';
    const windowName = '시험 시작하기';
    const windowFeatures = 'width=800,height=600,left=100,top=100';
    window.open(url, windowName, windowFeatures);
  };

  const logout = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    setIsLoggedIn(false);
    navigate(`/`);
  };

  return (
    <div css={style.header}>
      <div className="logo-container">
        <img src={logo} alt="Logo" className="logo" />
      </div>
      <div className="menu">
        <button
          className={selectedMenu === 'prompts' ? 'selected' : ''}
          onClick={() => handleMenuClick('prompts')}
        >
          <span className="menu-text">수업관리</span>
        </button>
    {/*    <button
          className={selectedMenu === 'questions' ? 'selected' : ''}
          onClick={() => handleMenuClick('questions')}
        >
          <span className="menu-text">문항관리</span>
        </button>
  */}
        <button
          className={selectedMenu === 'results' ? 'selected' : ''}
          onClick={() => handleMenuClick('results')}
        >
          <span className="menu-text">결과관리</span>
        </button>
        <button
          className={selectedMenu === 'students' ? 'selected' : ''}
          onClick={() => handleMenuClick('students')}
        >
          <span className="menu-text">학급관리</span>
        </button>
        <button
          css={style.startExamButton}
          onClick={() => handleMenuClick('start-exam')}
        >
          <span className="button-text">시험 시작하기</span>
        </button>
      {/*  <button
          className={selectedMenu === 'data' ? 'selected' : ''}
          onClick={() => handleMenuClick('data')}
        >
          <span className="menu-text">데이터 수업</span>
          <span className="beta-tag">Beta</span>
</button> */}
      </div>
      <div className="logout-container">
        <button onClick={logout} className="logout-button">
          로그아웃
        </button>
      </div>
    </div>
  );
};

export default Header;
