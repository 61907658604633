/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { css, useTheme } from '@emotion/react';
import Switch from 'react-switch';
import useStudentData from '../../hooks/useStudentClassData';
import buttonStyles from '../../style/buttonStyles';
import api from '../../utils/Auth'; // 수정된 axios 인스턴스를 import 합니다.

const API_URL = process.env.REACT_APP_API_URL;

const styles = (theme) => ({
  studentManagement: css`
    padding: 20px;
    font-family: Arial, sans-serif;
    color: #333;
    .breadcrumb {
      font-size: 14px;
      color: #666;
      .current {
        color: ${theme.colors.brandmain};
        font-weight: bold;
      }
    }
    .studentTable {
      width: 100%;
      border-collapse: collapse;
      margin-top: 20px;
      th, td {
        border-bottom: 1px solid #ddd;
        padding: 8px;
        text-align: left;
      }
      .toggle {
        cursor: pointer;
      }
      .editButton {
        background-color: #4caf50;
        color: white;
        border: none;
        padding: 5px 10px;
        cursor: pointer;
        &:hover {
          background-color: #45a049;
        }
      }
      input {
        border: 1px solid #ddd;
        padding: 5px;
        width: 90%;
      }
    }
    .pagination {
      margin-top: 20px;
      button {
        margin-right: 10px;
        padding: 10px 20px;
        border: none;
        background-color: #f5f5f5;
        color: black;
        cursor: pointer;
        border-radius: 5px;
        &:hover {
          background-color: #e5e5e5;
        }
        .selected {
          background-color: #d5d5d5;
        }
      }
    }

    .adminSection {
      margin-top: 20px;
      .inviteCode {
        margin-bottom: 20px;
        font-size: 18px;
        font-weight: bold;
      }
      .adminTable {
        width: 100%;
        border-collapse: collapse;
        th, td {
          border-bottom: 1px solid #ddd;
          padding: 8px;
          text-align: left;
        }
        .deleteButton {
          background-color: #f44336;
          color: white;
          border: none;
          padding: 5px 10px;
          cursor: pointer;
          &:hover {
            background-color: #d32f2f;
          }
        }
      }
    }
  `,
  pdfButton: css`
    margin-left: 10px;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
      background-color: #0056b3;
    }
  `
});

const StudentsRigist = ({ classData, selectedClass }) => {
  const theme = useTheme();
  const style = styles(theme);
  const [currentPage, setCurrentPage] = useState(1);
  const [view, setView] = useState('students');
  const studentsPerPage = 10;

  const { students, toggleActive, handleNameChange } = useStudentData(classData.id);

  const indexOfLastStudent = currentPage * studentsPerPage;
  const indexOfFirstStudent = indexOfLastStudent - studentsPerPage;
  const currentStudents = students.slice(indexOfFirstStudent, indexOfLastStudent);
  const totalPages = Math.ceil(students.length / studentsPerPage);

  const goToPage = (pageNumber) => setCurrentPage(pageNumber);

  const handleEdit = async (index) => {
    const student = students[index];
    const formattedUsername = `${formatUsername(student.username)}_${String(student.classroom_student_id).padStart(2, '0')}`;

    try {
      const response = await api.put(`${API_URL}/teachers/classrooms/${classData.id}/students`, 
      {
        user_id: student.id,
        username: formattedUsername,
        is_active: student.is_active,
      });

      if (response.status === 200) {
        alert(`학생 ${formattedUsername} 수정 완료`);
      }
    } catch (error) {
      console.error('Error updating student:', error);
      alert('학생 수정 중 오류가 발생했습니다.');
    }
  };

  const formatUsername = (username) => username.split('_')[0];

  const handlePrint = () => {
    const activeStudents = students.filter(student => student.is_active);
    const pages = Math.ceil(activeStudents.length / 20);

    let printContent = '';
    for (let i = 0; i < pages; i++) {
      const start = i * 20;
      const end = start + 20;
      const pageData = activeStudents.slice(start, end);
      printContent += `
        <table border="1" style="width: 100%; border-collapse: collapse; margin-bottom: 20px;">
          <thead>
            <tr style="height: 30px;">
              <th>학생 번호</th>
              <th>학급 ID</th>
              <th>학생 이름</th>
              <th>학생 코드</th>
            </tr>
          </thead>
          <tbody>
      `;

      pageData.forEach(student => {
        printContent += `
          <tr style="height: 30px;">
            <td>${student.classroom_student_id}</td>
            <td>${classData.class_code}</td>
            <td>${formatUsername(student.username)}</td>
            <td>${student.password}</td>
          </tr>
        `;
      });

      printContent += `
          </tbody>
        </table>
      `;

      if (i < pages - 1) {
        printContent += '<div style="page-break-after: always;"></div>';
      }
    }

    const printWindow = window.open('', '', 'height=800,width=800');
    printWindow.document.write('<html><head><title>Print</title></head><body>');
    printWindow.document.write(printContent);
    printWindow.document.write('</body></html>');
    printWindow.document.close();
    printWindow.print();
  };

  return (
    <div>
      <div css={style.studentManagement}>
        <div className="headerButtons">
          <button
            css={buttonStyles(theme)}
            className={view === 'students' ? 'selected' : ''}
            onClick={() => setView('students')}
          >
            학생정보
          </button>
          <button
            css={buttonStyles(theme)}
            className={view === 'permissions' ? 'selected' : ''}
            onClick={() => setView('permissions')}
          >
            초대코드
          </button>
          <button onClick={handlePrint} css={style.pdfButton}>
            PDF 다운로드
          </button>
        </div>

        {view === 'students' && (
          <>
            <table className="studentTable">
              <thead>
                <tr>
                  <th>학생 번호</th>
                  <th>학급 ID</th>
                  <th>학생 이름</th>
                  <th>학생 코드</th>
                  <th>활성화 여부</th>
                  <th>수정</th>
                </tr>
              </thead>
              <tbody>
                {currentStudents.map((student, index) => (
                  <tr key={student.id}>
                    <td>{student.classroom_student_id}</td>
                    <td>{classData.class_code}</td>
                    <td>
                      <input
                        type="text"
                        value={formatUsername(student.username)}
                        onChange={(e) => handleNameChange(indexOfFirstStudent + index, e.target.value)}
                      />
                    </td>
                    <td>{student.password}</td>
                    <td>
                      <Switch
                        onChange={() => toggleActive(indexOfFirstStudent + index)}
                        checked={student.is_active}
                        offColor="#888"
                        onColor="#0f0"
                      />
                    </td>
                    <td>
                      <button
                        className="editButton"
                        onClick={() => handleEdit(indexOfFirstStudent + index)}
                      >
                        수정
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="pagination">
              {[...Array(totalPages)].map((_, index) => (
                <button key={index} onClick={() => goToPage(index + 1)}>
                  {index + 1}
                </button>
              ))}
            </div>
          </>
        )}

        {view === 'permissions' && (
          <div className="adminSection">
            <div className="inviteCode">초대 코드: {classData.invite_code}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default StudentsRigist;
