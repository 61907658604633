/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useState, useCallback } from 'react';
import AnswerInput from './AnswerInput';
import ConfirmationModal from './ConfirmationModal';

const answerZoneStyle = css`
  flex: 4;
  padding: 20px;
  box-sizing: border-box;
  height: 100vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

const submitButton = css`
  background-color: #98510F;
  width: 100%;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  margin-top: 30px;
  font-size: 16px;
  cursor: pointer;
`;

const AnswerZone = ({ onSubmit, problem_count }) => {
  const [answers, setAnswers] = useState(() => {
    let initialAnswers = {};
    for (let i = 1; i <= problem_count; i++) {
      initialAnswers[i] = '';
    }
    return initialAnswers;
  });

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleAnswerChange = useCallback((event, index) => {
    const newAnswer = event.target.value;
    setAnswers(prev => ({ ...prev, [index]: newAnswer }));
  }, []);

  const convertAnswersToArray = useCallback((answersObj) => {
    const sortedKeys = Object.keys(answersObj).sort((a, b) => a - b);
    return sortedKeys.map(key => answersObj[key]);
  }, []);

  const handleSubmitAnswer = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const handleConfirmSubmit = useCallback(() => {
    const answersArray = convertAnswersToArray(answers);
    onSubmit(answersArray);
    setIsModalOpen(false);
  }, [answers, convertAnswersToArray, onSubmit]);

  const handleCancelSubmit = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  return (
    <div css={answerZoneStyle}>
      <div>정답 입력</div>
      {Object.keys(answers).map((key) => (
        <AnswerInput
          key={key}
          id={key}
          index={key}
          value={answers[key]}
          handleAnswerChange={handleAnswerChange}
        />
      ))}
      <button css={submitButton} onClick={handleSubmitAnswer}>정답 제출</button>
      {isModalOpen && (
        <ConfirmationModal
          onConfirm={handleConfirmSubmit}
          onCancel={handleCancelSubmit}
        />
      )}
    </div>
  );
};

export default AnswerZone;
