import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const api = axios.create({
  baseURL: API_URL,
  headers: {},
  withCredentials: true,  
});


api.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem('access_token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    const tokenExpiredMessage = "Token has expired";

    if (error.response && error.response.status === 401 && error.response.data.detail === tokenExpiredMessage) {
      try {
        const response = await axios.post(`${API_URL}/auth/refresh`);
        if (response.data) {
          localStorage.setItem('access_token', response.data.access_token);
          api.defaults.headers.common['Authorization'] = `Bearer ${response.data.access_token}`;
          originalRequest.headers['Authorization'] = `Bearer ${response.data.access_token}`;
          return api(originalRequest);
        }
      } catch (err) {
        console.error('Token refresh failed:', err);
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        // window.location.href = '/';
        return Promise.reject(error); // 추가: 여기서 무한 루프 방지를 위해 에러를 리턴합니다.
      }
    } else if (error.response && error.response.status === 401) {
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      // window.location.href = '/';
    }
    return Promise.reject(error);
  }
);

export default api;
