import { useState, useEffect } from 'react';
import api from '../utils/Auth'; // 수정된 axios 인스턴스를 import 합니다.

const API_URL = process.env.REACT_APP_API_URL;

const useClasses = () => {
  const [classes, setClasses] = useState([]);
  const [error, setError] = useState(null);

  const fetchClasses = async () => {

    try {
      const response = await api.get(`${API_URL}/teachers/classrooms`);
      if (response.data) {
        setClasses(response.data);
      }
    } catch (error) {
      console.error('Error fetching class data:', error);
      setError(error);
    }
  };

  useEffect(() => {
    fetchClasses();
  }, []);

  const addClass = async (className) => {
    try {
      const response = await api.post(
        `${API_URL}/teachers/classrooms`,
        { classroom_name: className },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      if (response.data) {
        setClasses((prevClasses) => [...prevClasses, response.data]);
        window.location.reload();
      }
    } catch (error) {
      console.error('Error adding new class:', error);
      setError(error);
    }
  };

  const joinClass = async (inviteCode) => {
    try {
      const response = await api.post(`${API_URL}/teachers/classrooms/join`, { classroom_invite_code: inviteCode });
      if (response.data) {
        fetchClasses();  // Refresh the class list after joining a new class
        window.location.reload();
      }
    } catch (error) {
      console.error('Error joining class:', error);
      setError(error);
    }
  };

  return { classes, error, addClass, joinClass };
};

export default useClasses;
