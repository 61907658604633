import React from 'react';

const AnswerForm = ({ currentProblem, initialAnswers, handleAnswerChange, notSolved }) => {
  // 객체 키 유효성 검사
  const isValidAnswer = initialAnswers.hasOwnProperty(currentProblem - 1);
  const isSolved = notSolved[currentProblem - 1];

  if (!isValidAnswer) {
    return <div>Invalid problem or not solved array</div>;
  }

  return (
    <div style={{ marginTop: '10px' }}>
      <>
        정답 수정:
        <input
          type="text"
          value={initialAnswers[currentProblem - 1] || ""}
          onChange={(e) => handleAnswerChange(e.target.value, currentProblem - 1)}
          style={styles.input}
          disabled={isSolved}
        />
      </>
    </div>
  );
};

const styles = {
  input: {
    width: '100%',
    height: '30px',
    padding: '10px',
    borderRadius: '5px',
    border: '1px solid #ccc',
  },
  submitButton: {
    marginTop: '10px',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '5px',
    backgroundColor: '#28a745',
    color: 'white',
    cursor: 'pointer',
  },
};

export default AnswerForm;
