/** @jsxImportSource @emotion/react */

import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import Header from '../Header';
import LeftPage from './leftPage';
import { ClipLoader } from 'react-spinners';
import { css } from '@emotion/react';
import useFetchStudentData from '../../hooks/useFetchStudentData'; // 커스텀 훅 import
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { Document, Page, pdfjs } from 'react-pdf';
import zoombuttonStyles from '../../style/zoombuttonStyles';

const API_URL = process.env.REACT_APP_API_URL;

const Solvepage = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [pdfUrl, setPdfUrl] = useState('');
  const [pdfDocument, setPdfDocument] = useState(null); // PDF 문서 캐싱을 위한 상태
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [scale, setScale] = useState(1.0);
  const { id } = useParams();
  const pdfInstanceRef = useRef(null);

  const token = localStorage.getItem('access_token');
  const { problemSetId, error, loading } = useFetchStudentData(token, id); // 커스텀 훅 사용

  useEffect(() => {
    if (token) {
      setIsLoggedIn(true);
    }
  }, [token]);

  useEffect(() => {
    if (problemSetId) {
      setPdfUrl(`${API_URL}/students/files/problem-sets/${problemSetId}`);
    }
  }, [problemSetId]);

  useEffect(() => {
    if (pdfUrl) {
      const loadingTask = pdfjs.getDocument({
        url: pdfUrl,
        httpHeaders: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
      });

      loadingTask.promise.then((pdf) => {
        setPdfDocument(pdf);
        setNumPages(pdf.numPages);
      }).catch(error => {
        console.error('Error loading PDF:', error);
      });

      pdfInstanceRef.current = loadingTask;
    }
  }, [pdfUrl]);

  const zoomIn = () => {
    setScale((prevScale) => prevScale + 0.2);
  };

  const zoomOut = () => {
    setScale((prevScale) => prevScale - 0.2);
  };

  return (
    <div className="App">
      <Header isLoggedIn={isLoggedIn} />
      <div className="solvecontent">
        {isSubmitting && (
          <div className="loading-overlay">
            <ClipLoader color="#ffffff" size={150} />
          </div>
        )}
        <div className='leftview'>
          <LeftPage />
        </div>
        <div className="pdfviewer">
          <div className="zoom-controls">
            <button css={zoombuttonStyles} onClick={zoomIn}>+</button>
            <button css={zoombuttonStyles} onClick={zoomOut}>-</button>
          </div>
          {pdfUrl && pdfDocument && (
            <Document
              file={{
                url: pdfUrl,
                httpHeaders: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
              }}
            >
              {Array.from(new Array(numPages), (el, index) => (
                <Page key={`page_${index + 1}`} pageNumber={index + 1} scale={scale} />
              ))}
            </Document>
          )}
        </div>
      </div>
    </div>
  );
};

export default Solvepage;

// CSS Styles
const styles = `
.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.solvecontent {
  display: flex;
  height: calc(100vh - 50px); /* Adjust for header height if needed */
}
.leftview{
  width:40%;
}

.pdfviewer {
  width: 60%;
  height: 100vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.zoom-controls {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.submitContainer {
  margin-top: 20px;
  text-align: center;
}
`;

// Inject the styles into the document
const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);
