/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {jwtDecode} from 'jwt-decode';
import logo from '../assets/logo.png';
import { css, useTheme } from '@emotion/react';

const styles = (theme) => ({
  header: css`
    background-color: ${theme.colors.sidebar};
    color: rgb(122, 144, 169);
    display: flex;
    align-items: center;
    padding: 10px 20px;
    transition: all 0.3s;
    .logo-container {
      display: flex;
      align-items: center;
      margin-right: auto;
      .logo {
        width: 90px;
        height: 30px;
        margin-right: 10px;
      }
      .brand-name {
        color: ${theme.colors.fontcolor};
      }
    }
    .menu {
      display: flex;
      align-items: center;
      button {
        display: flex;
        align-items: center;
        padding: 10px;
        font-size: 15px;
        background: none;
        border: none;
        color: ${theme.colors.buttonText};
        font-family: ${theme.fonts.main};
        font-weight: 700;
        margin: 0 10px;
        cursor: pointer;
        &:hover {
          background-color: ${theme.colors.buttonSelectedBg};
        }
        &.selected {
          color: black;
        }
      }
    }
    .user-info {
      margin-left: auto;
      display: flex;
      align-items: center;
      .username {
        margin-right: 15px;
        font-size: 14px;
        font-weight: bold;
        color: ${theme.colors.fontcolor};
      }
    }
    .logout-container {
      .logout-button {
        background: none;
        border: none;
        color: #ff4b5c;
        padding: 10px;
        cursor: pointer;
        font-size: 14px;
        font-weight: bold;
        &:hover {
          background-color: #ff4b5c;
          color: white;
        }
      }
    }
  `,
  startExamButton: css`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 15px;
    font-size: 16px;
    background-color: ${theme.colors.brandColor4};
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 0 10px;
    &:hover {
      background-color: ${theme.colors.primaryHover};
    }
  `,
});

const Header = ({ onSelect, setIsLoggedIn ,selectedSection}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const style = styles(theme);
  const [selectedMenu, setSelectedMenu] = useState('');
  const [username, setUsername] = useState('');

  useEffect(() => {
    setSelectedMenu(selectedSection);
  }, [selectedSection]);

  
  useEffect(() => {

    const token = localStorage.getItem('access_token');
    if (token) {
      try {
        const decoded = jwtDecode(token);
        setUsername(decoded.username);
      } catch (error) {
        console.error('Token decoding failed:', error);
      }
    }
  }, []);

  const handleMenuClick = (section) => {
    setSelectedMenu(section);
    onSelect(section);
  };

  const logout = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    setIsLoggedIn(false);
    navigate(`/`);
  };

  return (
    <div css={style.header}>
      <div className="logo-container">
        <img src={logo} alt="Logo" className="logo" />
      </div>
      <div className="menu">
        <button
          className={selectedMenu === 'studentexam' ? 'selected' : ''}
          onClick={() => handleMenuClick('studentexam')}
        >
          <span className="menu-text">문제풀기</span>
        </button>
        <button
          className={selectedMenu === 'incorrectnotes' ? 'selected' : ''}
          onClick={() => handleMenuClick('incorrectnotes')}
        >
          <span className="menu-text">오답노트</span>
        </button>
      </div>
      <div className="user-info">
        <span className="username">
          {username ? `${username.slice(0, -3)}님 안녕하세요!` : ''}
        </span>        
        <div className="logout-container">
          <button onClick={logout} className="logout-button">
            로그아웃
          </button>
        </div>
      </div>
    </div>
  );
};

export default Header;
