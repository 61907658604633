/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

const zoombuttonStyles = (theme) => css`
padding: 10px;
width: 60px;
margin: 0 5px;
background-color: #98510F;
border-radius: 10px;
color: white;
border: none;
cursor: pointer;
transition: background-color 0.3s;
&:hover {
  background-color: #0056b3;
}
`;

export default zoombuttonStyles;
