import { useState, useEffect } from 'react';

const useStatistics = (selectedProblemSet, studentData) => {
  const [statistics, setStatistics] = useState({
    firstSubmissionAvg: 0,
    overallAvg: 0,
    uniqueStudents: 0,
  });

  useEffect(() => {
    const calculateStatistics = async () => {

      if (selectedProblemSet && studentData.length) {
        const filteredData = studentData.filter((data) => data.problem_set_id === Number(selectedProblemSet));
        
        if (filteredData.length) {
          // Sort the filtered data by solved_at date
          filteredData.sort((a, b) => new Date(a.solved_at) - new Date(b.solved_at));
          
          // Create a map to store the earliest submission of each student
          const earliestSubmissionsMap = new Map();

          filteredData.forEach(data => {
            if (!earliestSubmissionsMap.has(data.student_name)) {
              earliestSubmissionsMap.set(data.student_name, data);
            }
          });

          // Convert the map values to an array of scores
          const firstSubmissionScores = Array.from(earliestSubmissionsMap.values()).map((data) => parseFloat(data.score));

          // Calculate overall scores
          const overallScores = filteredData.map((data) => parseFloat(data.score));

          // Calculate unique students
          const uniqueStudents = new Set(filteredData.map((data) => data.student_name)).size;

          // Calculate averages
          const firstSubmissionAvg = firstSubmissionScores.reduce((a, b) => a + b, 0) / firstSubmissionScores.length;
          const overallAvg = overallScores.reduce((a, b) => a + b, 0) / overallScores.length;

          setStatistics({
            firstSubmissionAvg,
            overallAvg,
            uniqueStudents,
          });
        }
      }
    };

    calculateStatistics();
  }, [selectedProblemSet, studentData]);

  return statistics;
};

export default useStatistics;
