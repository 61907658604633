import React from 'react';
import ReactDOM from 'react-dom/client';
import './App.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom'; // BrowserRouter import
import { ThemeProvider } from '@emotion/react';
import theme from './style/theme'; // 테마 파일 임포트
import GlobalStyles from './style/GlobalStyles';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
//  <React.StrictMode>
   <ThemeProvider theme={theme}>
    <GlobalStyles />

    <BrowserRouter> {/* BrowserRouter 추가 */}
      <App />
    </BrowserRouter>

    </ThemeProvider>

 // </React.StrictMode>
);

reportWebVitals();
