import { useState, useEffect } from 'react';
import api from '../utils/Auth'; // 수정된 axios 인스턴스를 import 합니다.

const API_URL = process.env.REACT_APP_API_URL;

const useUserData = () => {
  const [userdata, setUserdata] = useState([]);
  const [selectedProblemSet, setSelectedProblemSet] = useState(null);
  const [selectedProblemSetid, setSelectedProblemSetid] = useState(null);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {

    const fetchData = async () => {
      try {
        const response = await api.get(`${API_URL}/students/problem-data`);
        if (response.data) {
          const sortedData = response.data.sort((a, b) => new Date(a.solved_at) - new Date(b.solved_at));
          setUserdata(sortedData);
          
          if (sortedData.length > 0) {
            setSelectedProblemSet(sortedData[0].problem_set_name);
          }
        }
      } catch (error) {
        console.error('사용자 권한 확인 요청 에러:', error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return { userdata, selectedProblemSet, setSelectedProblemSet, loading, error };
};

export default useUserData;
