/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import { QRCodeCanvas } from 'qrcode.react';

const qrZoneStyle = css`
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const qrCodeStyle = css`
  margin-top: 20px;
`;

const urlStyle = css`
  font-size: 24px;
  font-weight: bold;
`;

const textStyle = css`
  font-size: 24px;
  margin-bottom: 30px;
`;

const Qrzone = () => {
  return (
    <div css={qrZoneStyle}>
        <div css={textStyle}>학생여러분! QR혹은 URL을 통해 입장하세요</div>

      <div css={urlStyle}>www.cocoaedu.com</div>
      <QRCodeCanvas value="https://cocoaedu.com" size={256} css={qrCodeStyle} />
    </div>
  );
}

export default Qrzone;
