/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { css, useTheme } from '@emotion/react';
import Questions from './Prompts/Question/index';
import Results from './Result/index';
import Prompts from './Prompts';
import Students from './ClassRigist/index';
import Exam from './Exam';
import Data from './Data/index'
const styles = (theme) => ({
  mainContent: css`
    flex: 1;
    position: relative;
    padding-left:30px;
    padding-right:30px;
    color: #919191;
  `,

  introSections: css`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    margin-bottom: 20px;
  `,
  introSection: css`
    padding: 20px;
    border-radius: 8px;
    h2 {
      margin: 0 0 10px;
      font-size: 18px;
      color: ${theme.colors.brandColor4};
    }
    p {
      margin: 0;
      font-size: 14px;
    }
  `,
  closeOverlay: css`
    background-color: ${theme.colors.brandColor4};
    border: none;
    color: white;
    padding: 10px 20px;
    font-size: 13px;
    cursor: pointer;
    &:hover {
      background-color: #21a1f1;
    }
  `,
  qrCode: css`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-left: 50%;
  `
});

const MainContent = ({ selectedSection }) => {

  const theme = useTheme();
  const style = styles(theme);

  let content;

  switch (selectedSection) {
    case 'exam':
      content = <Exam />;
      break;
    case 'questions':
      content = <Questions />;
      break;
    case 'results':
      content = <Results />;
      break;
    case 'prompts':
      content = <Prompts />;
      break;
    case 'students':
      content = <Students />;
      break;
    case 'data':
        content = <Data />;
        break;
    default:
      content = <Prompts />;
  }

  return (
    <div css={style.mainContent}>
      {content}
    </div>
  );
};

export default MainContent;
