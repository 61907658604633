/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { css, useTheme } from '@emotion/react';
import selectStyles from '../../style/selectStyles';
import useStudentData from '../../hooks/useStudentClassData';
import api from '../../utils/Auth'; // 수정된 axios 인스턴스를 import 합니다.
import { formatDate } from '../../utils/formatDate';

const API_URL = process.env.REACT_APP_API_URL;

const containerStyle = css`
  padding: 20px;
`;

const interactionContainerStyle = css`
  margin-top: 20px;
`;

const interactionItemStyle = css`
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const buttonContainerStyle = css`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
`;

const buttonStyle = (isSelected, theme) => css`
  padding: 10px 20px;
  background-color: ${isSelected ? '#007bff' : 'white'};
  color: ${isSelected ? 'white' : 'black'};
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
`;

const Conversation = () => {
  const theme = useTheme();
  const [classrooms, setClassrooms] = useState([]);
  const [selectedClassroom, setSelectedClassroom] = useState(null);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [selectedProblemId, setSelectedProblemId] = useState(null);
  const [interactions, setInteractions] = useState([]);
  const [selectedStudentName, setSelectedStudentName] = useState(null);
  const { students, toggleActive, handleNameChange } = useStudentData(selectedClassroom);

  useEffect(() => {
    const fetchClassrooms = async () => {
      try {
        const response = await api.get(`${API_URL}/teachers/classrooms`);
        setClassrooms(response.data);
      } catch (error) {
        console.error('Failed to fetch classrooms', error);
      }
    };

    fetchClassrooms();
  }, []);

  useEffect(() => {
    if (selectedClassroom) {
      const fetchInteractions = async () => {
        try {
          const response = await api.get(`${API_URL}/teachers/classrooms/${selectedClassroom}/students/interactions/socrates`);
          setInteractions(response.data.sort((a, b) => new Date(a.questioned_at) - new Date(b.questioned_at)));
          console.log(response)
        } catch (error) {
          console.error('Failed to fetch interactions', error);
        }
      };

      fetchInteractions();
    }
  }, [selectedClassroom]);

  const handleClassroomChange = (e) => {
    setSelectedClassroom(e.target.value);
    setSelectedStudent(null);
    setSelectedProblemId(null);
    setSelectedStudentName(null);
  };

  const handleStudentChange = (e) => {
    setSelectedStudent(e.target.value);
    setSelectedProblemId(null);
    setSelectedStudentName(null);
  };

  const handleStudentNameClick = (name) => {
    setSelectedStudentName(name === selectedStudentName ? null : name);
  };

  const filteredInteractions = selectedStudent
    ? interactions.filter(interaction => interaction.problem_set_name === selectedStudent)
    : interactions;

  const uniqueStudents = [...new Set(interactions.map(interaction => interaction.problem_set_name))];

  const uniqueStudentNames = [...new Set(filteredInteractions.map(interaction => interaction.student_name))];

  const uniqueProblemIds = selectedStudent
    ? [...new Set(filteredInteractions.map(interaction => interaction.problem_id))].sort((a, b) => a - b)
    : [];

  const finalFilteredInteractions = filteredInteractions.filter(interaction => {
    if (selectedProblemId && interaction.problem_id !== parseInt(selectedProblemId, 10)) {
      return false;
    }
    if (selectedStudentName && interaction.student_name !== selectedStudentName) {
      return false;
    }
    return true;
  });

  return (
    <div css={containerStyle}>
      <select css={selectStyles(theme)} onChange={handleClassroomChange} defaultValue="">
        <option value="" disabled>Select Classroom</option>
        {classrooms.map((classroom) => (
          <option key={classroom.id} value={classroom.id}>
            {classroom.name}
          </option>
        ))}
      </select>

      {selectedClassroom && (
        <select css={selectStyles(theme)} onChange={handleStudentChange} defaultValue="">
          <option value="" disabled>문제집 선택</option>
          {uniqueStudents.map((student, index) => (
            <option key={index} value={student}>
              {student}
            </option>
          ))}
        </select>
      )}

      {selectedStudent && (
        <select css={selectStyles(theme)} onChange={(e) => setSelectedProblemId(e.target.value)} defaultValue="">
          <option value="" disabled>문제 번호 선택</option>
          {uniqueProblemIds.map((problemId, index) => (
            <option key={index} value={problemId}>
              {problemId} 번
            </option>
          ))}
        </select>
      )}

      {selectedStudent && (
        <div css={buttonContainerStyle}>
          {uniqueStudentNames.map((studentName, index) => (
            <button
              key={index}
              css={buttonStyle(studentName === selectedStudentName, theme)}
              onClick={() => handleStudentNameClick(studentName)}
            >
              {studentName}
            </button>
          ))}
        </div>
      )}

      <div css={interactionContainerStyle}>
        {finalFilteredInteractions.map((interaction, index) => (
          <div key={index} css={interactionItemStyle}>
            <div><strong>문제집:</strong> {interaction.problem_set_name}</div>
            <div><strong>학생 질문:</strong> {interaction.student_input}</div>
            <div><strong>인공지능 답변:</strong> {interaction.response}</div>
            <div><strong>문제 번호:</strong> {interaction.problem_id}</div>
            <div><strong>학생 이름:</strong> {interaction.student_name}</div>
            <div><strong>Date:</strong> {formatDate(interaction.questioned_at)}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Conversation;
