import { useState, useEffect } from 'react';
import api from '../utils/Auth'; // 수정된 axios 인스턴스를 import 합니다.

const API_URL = process.env.REACT_APP_API_URL;

const useStudentClassData = (classId) => {
  const [students, setStudents] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {

      try {
        const response = await api.get(`${API_URL}/teachers/classrooms/${classId}/students`);
        const sortedStudents = response.data.sort((a, b) => a.id - b.id);
        setStudents(sortedStudents);
      } catch (error) {
        console.error('사용자 권한 확인 요청 에러:', error);
        setError(error);
      }
    };

    if (classId) {
      fetchData();
    }
  }, [classId]);

  const toggleActive = (index) => {
    const updatedStudents = [...students];
    updatedStudents[index].is_active = !updatedStudents[index].is_active;
    setStudents(updatedStudents);
  };

  const handleNameChange = (index, newName) => {
    const updatedStudents = [...students];
    updatedStudents[index].username = newName;
    setStudents(updatedStudents);
  };

  return { students, error, toggleActive, handleNameChange };
};

export default useStudentClassData;
