/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useEffect, useState, useCallback } from 'react';
import { pdfjs, Document, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { useNavigate, useParams } from 'react-router-dom';
import api from '../../utils/Auth'; // 수정된 axios 인스턴스를 import 합니다.
import { ClipLoader } from 'react-spinners';
import AnswerZone from './AnswerZone'; // 분리된 AnswerZone 파일을 import
import zoombuttonStyles from '../../style/zoombuttonStyles';
const API_URL = process.env.REACT_APP_API_URL;

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const containerStyle = css`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const solveContentStyle = css`
  display: flex;
  height: 100%;
`;

const viewerStyle = css`
  flex: 6;
  padding: 20px;
  box-sizing: border-box;
  height: 100vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

const zoomControlsStyle = css`
  margin-bottom: 10px;
`;

const Questionsolve = () => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [scale, setScale] = useState(1.4);
  const [pdfUrl, setPdfUrl] = useState('');
  const [problem_count, setProblem_count] = useState(0);
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [problem_id, setProblemsetid] = useState(null)

  const fetchVisibleProblems = useCallback(async () => {
    try {
      const response = await api.get(`${API_URL}/students/problem-sets/test`);
      if (response.data) {
        setProblem_count(response.data.problems_count);
        setPdfUrl(`${API_URL}/students/files/problem-sets/${response.data.problem_set_id}`);
        setIsDataFetched(true);
        setProblemsetid(response.data.problem_set_id)
      }
    } catch (error) {
      console.error('Error fetching problems or PDF file:', error);
    }
  }, []);

  useEffect(() => {
    fetchVisibleProblems();
  }, [fetchVisibleProblems]);

  const handleSubmitAnswer = useCallback(async (answersArray) => {
    setIsLoading(true);
    try {
      const response = await api.post(`${API_URL}/students/problem-sets/grade`, {
        problem_set_id: problem_id,
        student_answer: answersArray,
      });
      alert('정답이 제출되었습니다!');
      navigate('/');
    } catch (error) {
      console.error('Error submitting answers:', error);
    } finally {
      setIsLoading(false);
    }
  }, [problem_id, navigate]);

  const zoomIn = useCallback(() => {
    setScale((prevScale) => prevScale + 0.2);
  }, []);

  const zoomOut = useCallback(() => {
    setScale((prevScale) => prevScale - 0.2);
  }, []);

  return (
    <div css={containerStyle}>
      {isLoading && (
        <div className="loading-overlay">
          <div className="loader">
            <ClipLoader color="#ffffff" size={150} />
          </div>
        </div>
      )}
      <div css={solveContentStyle}>
        <div css={viewerStyle}>
          <div css={zoomControlsStyle}>
            <button css={zoombuttonStyles} onClick={zoomIn}>+</button>
            <button css={zoombuttonStyles} onClick={zoomOut}>-</button>
          </div>
          {pdfUrl && (
            <Document
              file={{
                url: pdfUrl,
                httpHeaders: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
              }}
              onLoadSuccess={({ numPages }) => setNumPages(numPages)}
            >
              {Array.from(new Array(numPages), (el, index) => (
                <Page key={`page_${index + 1}`} pageNumber={index + 1} scale={scale} />
              ))}
            </Document>
          )}
        </div>
        {isDataFetched && <AnswerZone onSubmit={handleSubmitAnswer} problem_count={problem_count} />}
      </div>
    </div>
  );
};

export default Questionsolve;
