/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react';
import { css, useTheme } from '@emotion/react';
import { FaFilter } from 'react-icons/fa';
import useClasses from '../hooks/useClasses';
import useProblemSets from '../hooks/useProblemSets';
import api from '../utils/Auth'; // 수정된 axios 인스턴스를 import 합니다.
import FilterModal from './FilterModal'; // 필터 모달을 import 합니다.

const API_URL = process.env.REACT_APP_API_URL;

const styles = (theme) => ({
  container: css`
    display: flex;
    height: 100vh;
    background-color: white;
    font-family: 'Arial, sans-serif';
  `,
  sidebar: css`
    border-right: 1px solid #ccc;
    padding: 20px;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  `,
  left: css`
    flex: 4;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
  `,
  right: (isExamOngoing) => css`
    flex: 6;
    flex-direction: column;
    justify-content: center;
    background-color: ${isExamOngoing ? 'white' : 'black'};
    align-items: center;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${isExamOngoing ? 'black' : 'white'};
    height: 100vh;
  `,
  rightTop: css`
    height: 10vh;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  rightBottom: css`
    height: 90vh;
    overflow-y: auto;
    width: 100%;
  `,
  list: css`
    list-style: none;
    padding: 0;
  `,
  listItem: css`
    padding: 15px;
    margin-bottom: 10px;
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 8px;
    text-align: center;
    transition: background-color 0.3s;
    &:hover {
      background-color: #e0e0e0;
    }
  `,
  selectedProblem: css`
    margin-bottom: 20px;
    margin-top: 30px;
    color: #333;
    font-size: 1.0em;
    font-weight: bold;
  `,
  button: css`
    padding: 12px 24px;
    font-size: 18px;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: background-color 0.3s;

    &:hover {
      background-color: #45a049;
    }

    svg {
      margin-right: 8px;
      fill: white;
    }
  `,
  buttonDisabled: css`
    padding: 12px 24px;
    font-size: 18px;
    background-color: grey;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: not-allowed;
    display: flex;
    align-items: center;
  `,
  buttonStop: css`
    padding: 12px 24px;
    font-size: 18px;
    background-color: #f44336;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: background-color 0.3s;

    &:hover {
      background-color: #e53935;
    }

    svg {
      margin-right: 8px;
      fill: white;
    }
  `,
  selectContainer: css`
    display: flex;
    align-items: center;
    margin-top: 30px;
  `,
  select: css`
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: #f5f5f5;
    border: none;
    border-radius: 10px;
    padding: 8px 25px 8px 12px;
    font-size: 14px;
    font-family: ${theme.fonts.main};
    color: #333;
    cursor: pointer;
    outline: none;
    background-image: url('data:image/svg+xml;charset=US-ASCII,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"><path fill="gray" d="M7 10l5 5 5-5z"/></svg>');
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 16px 16px;
    min-width: 120px;
  `,
  filterIcon: css`
    margin-left: 10px;
    cursor: pointer;
  `,
  table: css`
    margin-top: 20px;
    width: 100%;
    border-collapse: collapse;

    th, td {
      padding: 10px;
      border: 1px solid #ddd;
      text-align: center;
    }

    th {
      background-color: #f2f2f2;
    }

    .submitted {
      background-color: #d4edda;
    }
  `,
});

const Exam = () => {
  const [selectedProblemSet, setSelectedProblemSet] = useState(null);
  const [selectedProblemName, setSelectedProblemName] = useState('');
  const [selectedClass, setSelectedClass] = useState(null);
  const [isExamOngoing, setIsExamOngoing] = useState(false);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [filteredProblemSets, setFilteredProblemSets] = useState([]);
  const [submissionStatuses, setSubmissionStatuses] = useState([]);
  const theme = useTheme();
  const style = styles(theme);
  const { classes, error: classError } = useClasses();
  const { problemSets, error: problemSetError } = useProblemSets();

  useEffect(() => {
    if (selectedClass) {
      const checkCurrentExam = async () => {
        try {
          const response = await api.get(`${API_URL}/teachers/classrooms/tests/${selectedClass}/current-test`);
          if (response.data.problem_set_id) {
            setIsExamOngoing(true);
            setSelectedProblemSet(response.data.problem_set_id);
            setSelectedProblemName(response.data.problem_set_name);
            setSubmissionStatuses(response.data.submitted_students.filter(student => student.is_active));
          } else {
            setIsExamOngoing(false);
          }
        } catch (error) {
          if (error.response && error.response.status === 403) {
            setIsExamOngoing(false);
            setSelectedProblemSet(null);
            setSelectedProblemName('');
          } else {
            console.error('Error checking current exam:', error);
          }
        }
      };
      checkCurrentExam();
    }
  }, [selectedClass]);

  useEffect(() => {
    setFilteredProblemSets(problemSets);
  }, [problemSets]);

  const startExam = async () => {
    if (selectedProblemSet && selectedClass) {
      try {
        await api.post(
          `${API_URL}/teachers/classrooms/tests/${selectedClass}/start-test`,
          { problem_set_id: selectedProblemSet }
        );
        setIsExamOngoing(true);
        updateSubmissionStatus();
      } catch (error) {
        console.error('시험 시작 에러:', error);
      }
    } else {
      alert('문제집과 학급을 선택해주세요.');
    }
  };

  const stopExam = async () => {
    if (selectedProblemSet && selectedClass) {
      try {
        await api.put(
          `${API_URL}/teachers/classrooms/tests/${selectedClass}/stop-test`
        );
        setIsExamOngoing(false);
        setSelectedProblemSet(null);
        setSelectedProblemName('');
      } catch (error) {
        if (error.response && error.response.status === 403) {
          setIsExamOngoing(false);
          setSelectedProblemSet(null);
          setSelectedProblemName('');
        } else {
          console.error('시험 중지 에러:', error);
        }
      }
    }
  };

  const updateSubmissionStatus = async () => {
    if (selectedClass) {
      try {
        const response = await api.get(`${API_URL}/teachers/classrooms/tests/${selectedClass}/current-test`);
        if (response.data.problem_set_id) {
          setSubmissionStatuses(response.data.submitted_students.filter(student => student.is_active));
        }
      } catch (error) {
        console.error('Error updating submission status:', error);
      }
    }
  };

  const handleFilterApply = (subjects, grades) => {
    const filtered = problemSets.filter(problem =>
      (subjects.length === 0 || subjects.includes(problem.subject)) &&
      (grades.length === 0 || grades.includes(problem.grade))
    );
    setFilteredProblemSets(filtered);
    setIsFilterModalOpen(false);
  };

  // Sort the submissionStatuses array by classroom_student_number before rendering
  const sortedSubmissionStatuses = [...submissionStatuses].sort((a, b) => a.classroom_student_number - b.classroom_student_number);

  return (
    <div css={style.container}>
      <div css={style.left}>
        <button
          css={(selectedProblemSet && selectedClass) ? (isExamOngoing ? style.buttonStop : style.button) : style.buttonDisabled}
          onClick={isExamOngoing ? stopExam : startExam}
          disabled={!(selectedProblemSet && selectedClass)}
        >
          <svg width="24" height="24" viewBox="0 0 24 24">
            {isExamOngoing ? (
              <path d="M6 6h12v12H6z"></path>
            ) : (
              <path d="M8 5v14l11-7z"></path>
            )}
          </svg>
          {isExamOngoing ? '시험 종료' : '시험 시작'}
        </button>
        {selectedProblemName ? (
          <div css={style.selectedProblem}>선택된 문제집: {selectedProblemName}</div>
        ) : (
          <div css={style.selectedProblem}> 학급과 문제를 선택해주세요 </div>
        )}
        <div css={style.selectContainer}>
          <select
            value={selectedClass || ''}
            onChange={(e) => setSelectedClass(e.target.value)}
            css={style.select}
          >
            <option value="" >학급을 선택하세요</option>
            {classes.map((item, index) => (
              <option key={index} value={item.id}>{item.name}</option>
            ))}
          </select>
          <FaFilter css={style.filterIcon} size={24} onClick={() => setIsFilterModalOpen(true)} />
        </div>
        <div css={style.buttonzone}>
          {filteredProblemSets.length > 0 ? (
            <ul css={style.list}>
              {filteredProblemSets.map((item, index) => (
                <li
                  key={index}
                  css={style.listItem}
                  onClick={() => {
                    if (!isExamOngoing) {
                      setSelectedProblemSet(item.problem_set_id);
                      setSelectedProblemName(item.problem_set_name);
                    }
                  }}
                  style={{
                    backgroundColor:
                      selectedProblemSet === item.problem_set_id ? '#e0e0e0' : 'white',
                    cursor: isExamOngoing ? 'not-allowed' : 'pointer',
                  }}
                >
                  {item.problem_set_name}
                </li>
              ))}
            </ul>
          ) : (
            <p>해당 문제집이 없습니다.</p>
          )}
        </div>
      </div>
      <div css={style.right(isExamOngoing)}>
        {isExamOngoing ? (
          <>
            <div css={style.rightTop}>
              <button css={style.button} onClick={updateSubmissionStatus}>제출 현황 갱신</button>
            </div>
            <div css={style.rightBottom}>
              <table css={style.table}>
                <thead>
                  <tr>
                    <th>번호</th>
                    <th>이름</th>
                    <th>제출 상태</th>
                  </tr>
                </thead>
                <tbody>
                  {sortedSubmissionStatuses.map((student, index) => (
                    <tr key={index} className={student.has_submitted ? 'submitted' : ''}>
                      <td>{student.classroom_student_number}</td>
                      <td>{student.student_name.slice(0, -3)}</td>          
                      <td>{student.has_submitted ? '제출' : '미제출'}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        ) : (
          '시험 시작전입니다.'
        )}
      </div>
      <FilterModal
        isOpen={isFilterModalOpen}
        onClose={() => setIsFilterModalOpen(false)}
        onApply={handleFilterApply}
        theme={theme}
      />
    </div>
  );
};

export default Exam;
