/** @jsxImportSource @emotion/react */
import { css, Global, useTheme } from '@emotion/react';

const GlobalStyles = () => {
  const theme = useTheme();

  return (
    <Global
      styles={css`
        @font-face {
          font-family: 'Pretendard-Regular';
          src: url('https://fastly.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
          font-weight: 400;
          font-style: normal;
        }

        body {
          margin: 0;
          padding: 0;
          font-weight: 400; /* 기본 글자 두께 설정 */
          font-family: ${theme.fonts.main};
          background-color: #fafafc;
          color: ${theme.colors.fontcolor};
        }
      `}
    />
  );
};

export default GlobalStyles;
