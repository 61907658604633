import React, { useState, useEffect } from 'react';
import { CSVLink } from 'react-csv';
import Header from './Sidebar';
import api from '../utils/Auth';

const API_URL = process.env.REACT_APP_API_URL;

const AdminMain = () => {
  const [data, setData] = useState([]);

  const fetchData = async () => {
    try {
      const response = await api.get(`${API_URL}/admin/data/socrates`);
      if (response.data) {
        console.log(response.data);
        setData(response.data);
      }
    } catch (error) {
      console.error('Error fetching class data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Define CSV headers
  const headers = [
    { label: 'GPT 응답', key: 'gpt 응답' },
    { label: '교실 이름', key: '교실 이름' },
    { label: '문제 번호', key: '문제 번호' },
    { label: '문제집 이름', key: '문제집 이름' },
    { label: '선생님 이메일', key: '선생님 이메일' },
    { label: '점수', key: '점수' },
    { label: '정답 여부', key: '정답 여부' },
    { label: '테스트 제출 날짜', key: '테스트 제출 날짜' },
    { label: '프롬프트 질문 날짜', key: '프롬프트 질문 날짜' },
    { label: '학생 만족도 평가', key: '학생 만족도 평가' },
    { label: '학생 번호', key: '학생 번호' },
    { label: '학생 이름', key: '학생 이름' },
    { label: '학생의 정답', key: '학생의 정답' },
    { label: '학생의 질문', key: '학생의 질문' },
  ];

  return (
    <>
      <Header />
      <div>Hello Admin</div>
      <div>
        <CSVLink data={data} headers={headers} filename={"admin_data.csv"}>
          Download CSV
        </CSVLink>
      </div>
    </>
  );
};

export default AdminMain;
