import React, { useState, useEffect, useRef } from 'react';
import api from '../../utils/Auth'; // 수정된 axios 인스턴스를 import 합니다.
import useInteractionsCount from '../../hooks/useInteractionsCount'; // useInteractionsCount 훅을 import 합니다.

const API_URL = process.env.REACT_APP_API_URL;

const Chat = ({
  currentProblem, problemMessages, setProblemMessages, messages, setMessages, setIsLoading, isLoading, solvedata
}) => {
  const [input, setInput] = useState('');
  const chatEndRef = useRef(null);
  const { interactionsCount, error } = useInteractionsCount(); // 훅을 사용합니다.
  const [remainingInteractions, setRemainingInteractions] = useState(interactionsCount);
  const [initialFetched, setInitialFetched] = useState(Array(100).fill(false)); // Assume max 100 problems

  useEffect(() => {
    if (interactionsCount !== null) {
      setRemainingInteractions(interactionsCount);
    }
  }, [interactionsCount]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    // Fetch initial message when currentProblem changes
    if (currentProblem !== null && !initialFetched[currentProblem - 1]) {
      fetchInitialMessage();
    }
  }, [currentProblem]);

  const scrollToBottom = () => {
    if (chatEndRef.current) {
      chatEndRef.current.parentNode.scrollTop = chatEndRef.current.offsetTop;
    }
  };

  const fetchInitialMessage = async () => {
    setIsLoading(true);
    try {
      const response = await api.post(`${API_URL}/students/interactions/initial`, {
          problem_set_id: solvedata.problem_set_id,
          problem_id: currentProblem,
          
      });
      
      const initialMessage = response.data.response.content;

      const newMessages = [...problemMessages];
      newMessages[currentProblem - 1] = [
        { sender: 'bot', text: initialMessage }
      ];

      setProblemMessages(newMessages);
      setMessages(newMessages[currentProblem - 1]);

      const updatedInitialFetched = [...initialFetched];
      updatedInitialFetched[currentProblem - 1] = true;
      setInitialFetched(updatedInitialFetched);
    } catch (error) {
      console.error('Initial message fetch error:', error);
    }

    setIsLoading(false);
  };

  const handleSendMessage = async (event) => {
    event.preventDefault();
    if (!input.trim() || currentProblem === null || remainingInteractions <= 0) return;

    const newMessages = [...problemMessages];
    newMessages[currentProblem - 1] = [
      ...newMessages[currentProblem - 1],
      { sender: 'user', text: input },
      { sender: 'bot', text: '인공지능이 답변을 생성중입니다...' }
    ];
    setProblemMessages(newMessages);
    setMessages(newMessages[currentProblem - 1]);
    setInput('');

    setIsLoading(true);
    try {
      const response = await api.post(`${API_URL}/students/interactions/socrates`, {
        problem_set_id: solvedata.problem_set_id,
        problem_id: currentProblem,
        student_input: input,
      });

      const botResponse = response.data;

      newMessages[currentProblem - 1].pop();
      newMessages[currentProblem - 1] = [
        ...newMessages[currentProblem - 1],
        { sender: 'bot', text: botResponse.response.content }
      ];

      setProblemMessages(newMessages);
      setMessages(newMessages[currentProblem - 1]);

      setRemainingInteractions(prev => prev - 1);
    } catch (error) {
      console.error('메시지 전송 에러:', error);
    }

    setIsLoading(false);
  };

  return (
    <div>
      <div>
        질문 가능 횟수: {remainingInteractions !== null ? remainingInteractions : -1}/100
      </div>
      <div id="chatMessages" style={styles.chatMessages}>
        {messages && messages.length === 0 && (
          <div style={styles.initialMessage}>
            {currentProblem}번 문제와 관련한 질문을 해주세요<br/> 인공지능 선생님이 여러분을 도와줄거에요! <br/> 질문내용은 선생님에게 전달됩니다^^
          </div>
        )}
        {messages && messages.map((msg, index) => (
          <div key={index} style={{
            ...styles.message,
            ...msg.sender === 'user' ? styles.userMessage : styles.botMessage
          }}>
            {msg.text}
          </div>
        ))}
        <div ref={chatEndRef} />
      </div>
      <div>
      </div>
      <form onSubmit={handleSendMessage} style={styles.form}>
        <input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          style={styles.input}
          disabled={isLoading || remainingInteractions <= 0}
        />
        <button type="submit" style={styles.sendButton} disabled={isLoading || remainingInteractions <= 0}>
          보내기
        </button>
      </form>
    </div>
  );
};


const styles = {
  chatMessages: {
    flexGrow: 1,
    width: '100%',
    overflowY: 'auto',
    padding: '10px',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    marginTop: '10px',
    border: '1px solid #ccc',
    borderRadius: '5px',
    height: '300px',
    backgroundColor: '#f8f8f8',
  },
  initialMessage: {
    alignSelf: 'center',
    maxWidth: '70%',
    padding: '10px',
    borderRadius: '10px',
    backgroundColor: '#f1f1f1',
    color: 'black',
    position: 'relative',
    wordBreak: 'break-word',
    borderBottomLeftRadius: '0',
  },
  message: {
    maxWidth: '70%',
    padding: '10px',
    borderRadius: '10px',
    position: 'relative',
    wordBreak: 'break-word',
  },
  userMessage: {
    alignSelf: 'flex-end',
    backgroundColor: '#007bff',
    color: 'white',
    borderBottomRightRadius: '0',
  },
  botMessage: {
    alignSelf: 'flex-start',
    backgroundColor: '#f1f1f1',
    color: 'black',
    borderBottomLeftRadius: '0',
  },
  suggestionButton: {
    padding: '5px 10px',
    margin: '5px',
    borderRadius: '5px',
    backgroundColor: '#f1f1f1',
    border: "none",
    color: 'black',
    cursor: 'pointer',
  },
  form: {
    display: 'flex',
    marginTop: '10px',
  },
  input: {
    flexGrow: 1,
    marginRight: '10px',
    padding: '5px',
    borderRadius: '5px',
    border: '1px solid #ccc',
  },
  sendButton: {
    padding: '5px 10px',
    border: 'none',
    borderRadius: '5px',
    backgroundColor: '#007bff',
    color: 'white',
    cursor: 'pointer',
  },
};

export default Chat;
