/** @jsxImportSource @emotion/react */
import React, { useState, useEffect, useRef } from 'react';
import { css, useTheme } from '@emotion/react';
import { Line } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';

import { Chart as ChartJS, CategoryScale, LinearScale, LineElement, Title, Tooltip, Legend, TimeScale, PointElement } from 'chart.js';
import Switch from 'react-switch';
import api from '../../utils/Auth'; // 수정된 axios 인스턴스를 import 합니다.

const API_URL = process.env.REACT_APP_API_URL;

ChartJS.register(CategoryScale, LinearScale, LineElement, Title, Tooltip, Legend, TimeScale, PointElement);

const styles = (theme) => ({
    maincontainer: css`
        display: flex;
        width: 100%;
        height: 80vh;
    `,
    ranking: css`
        width: 15%;
        background-color: white;
        margin-left: 20px;
        padding: 20px;
        border-radius: 10px;
        overflow-y: auto;
    `,
    rankingTitle: css`
        margin-bottom: 10px;
    `,
    rankingList: css`
        list-style-type: none;
        padding: 0;
    `,
    rankingItem: css`
        padding: 10px;
        border-bottom: 1px solid #ddd;
        &:last-child {
            border-bottom: none;
        }
        display: flex;
        justify-content: space-between;
    `,
    studentButton: (isSelected) => css`
        width: 100%;
        background: ${isSelected ? '#d3d3d3' : 'none'};
        border: none;
        padding: 10px;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        &:hover {
            background-color: ${isSelected ? '#d3d3d3' : '#f0f0f0'};
        }
    `,
    studentName: css`
        font-weight: 400;
    `,
    studentScore: css`
        font-weight: 600;
    `,
    chartContainer: css`
        width: 45%;
        background-color: white;
    `,
    rightzone: css`
        width: 40%;
        display: flex;
        flex-direction: column;
        padding: 20px;
        background-color: white;
        border-radius: 10px;
        margin-left: 20px;
        overflow-y: auto;
    `,
    detailedInfo: css`
        flex: 1;
        display: flex;
        flex-direction: column;
    `,
    toggle: css`
        display: flex;
        align-items: center;
    `,
    toggleLabel: css`
        margin-left: 10px;
    `,
    button: css`
        padding: 10px 20px;
        background-color: #007bff;
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        font-size: 14px;
        margin-top: 20px;
        align-self: center;
    `,
    detailedInfoItem: css`
        margin-bottom: 5px;
        padding: 5px;
        border: 1px solid #ccc;
        border-radius: 5px;
        font-size: 0.9em;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    `,
    questionContainer: css`
        flex: 1;
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        overflow-y: auto;
    `,
    questionItem: css`
        margin-bottom: 5px;
        padding: 5px;
        border: 1px solid #ccc;
        border-radius: 5px;
        cursor: pointer;
        font-size: 0.9em;
        display: flex;
        justify-content: space-between;
        align-items: center;
    `,
    questionCorrect: css`
        background-color: #e6fff4;
    `,
    questionIncorrect: css`
        background-color: #f8d7da;
    `,
    answerDetails: css`
        margin-top: 5px;
        padding: 5px;
        border: 1px solid #ccc;
        border-radius: 5px;
        font-size: 0.8em;
    `,
});

const Chart = ({ studentData, selectedProblemSet, selectedClass, fetchData }) => {
    const [viewMode, setViewMode] = useState('first');
    const [toggleChecked, setToggleChecked] = useState(false);
    const [selectedStudent, setSelectedStudent] = useState('');
    const [selectedData, setSelectedData] = useState([]);
    const [chartData, setChartData] = useState({});
    const [clickedPoint, setClickedPoint] = useState(null);
    const [answers, setAnswers] = useState([]);
    const theme = useTheme();
    const style = styles(theme);
    const chartRef = useRef(null);

    const fetchAnswers = async () => {
        try {
            const response = await api.get(`${API_URL}/teachers/problem-sets/${selectedProblemSet}/answer`);
            setAnswers(response.data);
        } catch (error) {
            console.error('Error fetching answers:', error);
        }
    };

    useEffect(() => {
        if (selectedStudent) {
            const filteredData = studentData
                .filter(data => data.problem_set_id === Number(selectedProblemSet) && data.student_name === selectedStudent)
                .sort((a, b) => new Date(a.solved_at) - new Date(b.solved_at));

            setSelectedData(filteredData);

            const labels = filteredData.map(data => new Date(data.solved_at));
            const scores = filteredData.map(data => data.score);

            setChartData({
                labels,
                datasets: [
                    {
                        label: 'Score',
                        data: scores,
                        fill: false,
                        borderColor: 'rgb(75, 192, 192)',
                        tension: 0.1
                    }
                ]
            });
        }

        fetchAnswers();
    }, [selectedStudent, studentData, selectedProblemSet]);

    const handleToggleChange2 = (checked) => {
        setToggleChecked(checked);
        setViewMode(checked ? 'all' : 'first');
    };

    const getChartData = () => {
        const filteredData = studentData.filter(
            (data) => data.problem_set_id === Number(selectedProblemSet)
        );

        const labels = [...new Set(filteredData.map(data => data.student_name))];

        const data = {
            labels,
            datasets: [
                {
                    label: '점수',
                    data: labels.map(label => {
                        const studentSubmissions = filteredData.filter(
                            data => data.student_name === label
                        );
                        if (viewMode === 'first') {
                            const firstSubmission = studentSubmissions.reduce((acc, curr) => {
                                const currentSubmissionDate = new Date(curr.solved_at);
                                if (!acc || currentSubmissionDate < new Date(acc.solved_at)) {
                                    return curr;
                                }
                                return acc;
                            }, null);
                            return firstSubmission ? parseFloat(firstSubmission.score) : 0;
                        } else {
                            const totalScore = studentSubmissions.reduce((acc, curr) => acc + parseFloat(curr.score), 0);
                            return totalScore / studentSubmissions.length;
                        }
                    }),
                    backgroundColor: 'rgba(75, 192, 192, 0.2)',
                    borderColor: 'rgba(75, 192, 192, 1)',
                    borderWidth: 0,
                }
            ],
        };

        return data;
    };

    const handleStudentSelect = (studentName) => {
        setSelectedStudent(studentName);
        setClickedPoint(null);
    };

    const handleChartClick = (e, elements) => {
        if (elements.length > 0) {
            const { index } = elements[0];
            setClickedPoint(selectedData[index]);
        }
    };

    const handleToggleChange = (index) => {
        setClickedPoint((prevData) => {
            const newIncorrectProblems = [...prevData.incorrect_problems];
            newIncorrectProblems[index] = !newIncorrectProblems[index];
            return { ...prevData, incorrect_problems: newIncorrectProblems };
        });
    };

    const getQuestionClass = (isCorrect) => {
        return isCorrect ? style.questionCorrect : style.questionIncorrect;
    };

    const renderQuestions = (data) => {
        if (!data) return null;

        const studentAnswers = data.student_answer || [];
        const incorrectProblems = data.incorrect_problems || [];

        return studentAnswers.map((answer, index) => {
            const isCorrect = incorrectProblems[index];
            return (
                <div key={index} css={[style.questionItem, getQuestionClass(isCorrect)]}>
                    <div>{`문제 ${index + 1}`}</div>
                    <div css={style.answerzone}>
                        <div css={style.answerDetails}>{`학생 답변: ${answer}`}</div>
                        <div css={style.answerDetails}>{`해답: ${answers[index].answer}`}</div>
                    </div>
                    <div css={style.toggle}>
                        <Switch
                            checked={isCorrect}
                            onChange={() => handleToggleChange(index)}
                            onColor="#00ff00"
                            offColor="#ff0000"
                            checkedIcon={<div style={{ color: 'white', padding: 2, fontWeight: 'bold' }}>O</div>}
                            uncheckedIcon={<div style={{ color: 'white', padding: 2, fontWeight: 'bold' }}>X</div>}
                        />
                    </div>
                </div>
            );
        });
    };

    const handleUpdateGrading = async () => {
        try {
            const response = await api.post(
                `${API_URL}/teachers/classrooms/${selectedClass}/students/problem-data`,
                {
                    student_problem_data_id: clickedPoint.student_problem_data_id,
                    student_is_correct: clickedPoint.incorrect_problems,
                }
            );
            alert('성공적으로 수정되었습니다');
            fetchData(selectedClass);
        } catch (error) {
            console.error('Error updating grading:', error);
            // Optionally, show an error message
        }
    };

    const getRankingData = () => {
        const chartData = getChartData();
        const studentScores = chartData.labels.map((label, index) => ({
            student_name: label,
            score: chartData.datasets[0].data[index],
        }));

        studentScores.sort((a, b) => b.score - a.score);

        return studentScores;
    };

    return (
        <div css={style.maincontainer}>
            <div css={style.ranking}>
                <Switch
                    onChange={handleToggleChange2}
                    checked={toggleChecked}
                    uncheckedIcon={<div style={{ fontSize: 12, color: 'white', paddingLeft: 2 }}>최초</div>}
                    checkedIcon={<div style={{ fontSize: 12, color: 'white', paddingLeft: 2 }}>평균</div>}
                    offColor="#98510F"
                    onColor="#858992"
                    height={24}
                    width={54}
                />
                <div css={style.rankingTitle}>학생 순위</div>
                <ol css={style.rankingList}>
                    {getRankingData().map((student, index) => (
                        <li key={index} css={style.rankingItem}>
                            <button
                                css={style.studentButton(student.student_name === selectedStudent)}
                                onClick={() => handleStudentSelect(student.student_name)}
                            >
                                <span css={style.studentName}>{student.student_name}</span>
                                <span css={style.studentScore}>{student.score.toFixed(2)}</span>
                            </button>
                        </li>
                    ))}
                </ol>
            </div>
            <div css={style.chartContainer}>
                {chartData.labels ? (
                    <Line
                        ref={chartRef}
                        data={chartData}
                        options={{
                            scales: {
                                x: {
                                    type: 'time',
                                    time: {
                                        unit: 'month'
                                    }
                                }
                            },
                            onClick: (e, elements) => handleChartClick(e, elements)
                        }}
                    />
                ) : (
                    <h3>학생 데이터를 선택하세요</h3>
                )}
            </div>
            <div css={style.rightzone}>
                <div css={style.detailedInfo}>
                    <div css={style.rankingTitle}>정오표</div>
                    <button css={style.button} onClick={handleUpdateGrading}>
                        채점 수정하기
                    </button>
                    {clickedPoint ? (
                        <div css={style.questionContainer}>
                            {renderQuestions(clickedPoint)}
                        </div>
                    ) : (
                        <h3>그래프의 점을 클릭하세요</h3>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Chart;
