/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { css, useTheme } from '@emotion/react';
import Incorrectnotes from './Incorrectnotes'
import StudentExam from './StudentExam';

const styles = (theme) => ({
  mainContent: css`
    flex: 1;
    position: relative;
    padding-left:30px;
    padding-right:30px;
    color: #919191;
  `,
  overlay: css`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    z-index: 10;
    h1 {
      display: flex;
      font-size: 40px;
      div {
        color: ${theme.colors.brandColor4};
      }
    }
    p {
      margin-bottom: 20px;
      font-size: 18px;
    }
  `,
  introSections: css`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    margin-bottom: 20px;
  `,
  introSection: css`
    padding: 20px;
    border-radius: 8px;
    h2 {
      margin: 0 0 10px;
      font-size: 18px;
      color: ${theme.colors.brandColor4};
    }
    p {
      margin: 0;
      font-size: 14px;
    }
  `,
  closeOverlay: css`
    background-color: ${theme.colors.brandColor4};
    border: none;
    color: white;
    padding: 10px 20px;
    font-size: 13px;
    cursor: pointer;
    &:hover {
      background-color: #21a1f1;
    }
  `,
  qrCode: css`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-left: 50%;
  `
});

const MainContent = ({ selectedSection }) => {
  const theme = useTheme();
  const style = styles(theme);

  let content;

  switch (selectedSection) {
    case 'studentexam':
      content = <StudentExam />;
      break;
      case 'incorrectnotes':
        content = <Incorrectnotes />;
      break;
  
  }

  return (
    <div css={style.mainContent}>
      {content}
    </div>
  );
};

export default MainContent;
