import { useState, useEffect } from 'react';
import api from '../utils/Auth'; // 수정된 axios 인스턴스를 import 합니다.

const API_URL = process.env.REACT_APP_API_URL;

const useProblemSets = () => {
  const [problemSets, setProblemSets] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {

    const fetchProblemSets = async () => {
      try {
        const response = await api.get(`${API_URL}/teachers/problem-sets`);
        setProblemSets(response.data);
      } catch (error) {
        console.error('Error fetching problem sets:', error);
        setError(error);
      }
    };

    fetchProblemSets();
  }, []);

  return { problemSets, error };
};

export default useProblemSets;
