/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react';
import { css, useTheme } from '@emotion/react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement } from 'chart.js';
import Switch from 'react-switch';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement);

const styles = (theme) => ({
  maincontainer: css`
    display: flex;
    width: 100%;
    height: 80vh;
  `,
  chartzone: css`
    width: 60%;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
  `,
  ranking: css`
    width: 40%;
    background-color: white;
    margin-left: 20px;
    padding: 20px;
    border-radius: 10px;
    overflow-y: auto;
  `,
  rankingTitle: css`

    margin-bottom: 10px;
  `,
  rankingList: css`
    list-style-type: none;
    padding: 0;
  `,
  rankingItem: css`
    padding: 10px;
    border-bottom: 1px solid #ddd;
    &:last-child {
      border-bottom: none;
    }
    display: flex;
    justify-content: space-between;
  `,
  studentName: css`
    font-weight: 400;
  `,
  studentScore: css`
    font-weight: 600;
  `,
});

const Unsolved = ({ studentData, selectedProblemSet, submit }) => {
  const [viewMode, setViewMode] = useState('first');
  const [toggleChecked, setToggleChecked] = useState(false);
  const [incorrectProblems, setIncorrectProblems] = useState([]);
  const [problemRates, setProblemRates] = useState([]);

  const theme = useTheme();
  const style = styles(theme);

  useEffect(() => {
    if (selectedProblemSet && studentData.length) {
      const filteredData = studentData.filter(
        (data) => data.problem_set_id === Number(selectedProblemSet)
      );

      // Sort by solved_at date
      const sortedData = filteredData.sort((a, b) => new Date(a.solved_at) - new Date(b.solved_at));

      // Reduce to earliest data per student
      const earliestDataByStudent = sortedData.reduce((acc, data) => {
        if (!acc[data.student_name]) {
          acc[data.student_name] = data;
        }
        return acc;
      }, {});

      const earliestDataArray = Object.values(earliestDataByStudent);

      if (earliestDataArray.length) {
        const incorrectCounts = earliestDataArray.reduce((acc, curr) => {
          curr.incorrect_problems.forEach((isCorrect, index) => {
            if (!isCorrect) {
              const problemNumber = index + 1; // Assuming the problem numbers are 1-based
              if (!acc[problemNumber]) {
                acc[problemNumber] = 0;
              }
              acc[problemNumber]++;
            }
          });
          return acc;
        }, {});

        const sortedIncorrectCounts = Object.entries(incorrectCounts).sort(([, a], [, b]) => b - a);

        setIncorrectProblems(sortedIncorrectCounts);
      }
    }
  }, [selectedProblemSet, studentData]);

  useEffect(() => {
    if (selectedProblemSet && studentData.length) {
      const filteredData = studentData.filter(
        (data) => data.problem_set_id === Number(selectedProblemSet)
      );

      // Sort by solved_at date
      const sortedData = filteredData.sort((a, b) => new Date(a.solved_at) - new Date(b.solved_at));

      // Reduce to earliest data per student
      const earliestDataByStudent = sortedData.reduce((acc, data) => {
        if (!acc[data.student_name]) {
          acc[data.student_name] = data;
        }
        return acc;
      }, {});

      const earliestDataArray = Object.values(earliestDataByStudent);

      const problemIncorrectCounts = {};
      let maxProblemNumber = 0;

      earliestDataArray.forEach((curr) => {
        curr.incorrect_problems.forEach((isCorrect, index) => {
          if (!isCorrect) {
            const problemNumber = index + 1; // Assuming the problem numbers are 1-based
            if (problemNumber > maxProblemNumber) {
              maxProblemNumber = problemNumber;
            }
            problemIncorrectCounts[problemNumber] = (problemIncorrectCounts[problemNumber] || 0) + 1;
          }
        });
      });

      const problemSubmissionCounts = Array(maxProblemNumber).fill(submit);

      const rates = Object.keys(problemIncorrectCounts).map((problem) => {
        const totalIncorrect = problemIncorrectCounts[problem] || 0;
        const accuracyRate = ((totalIncorrect) / submit) * 100;
        return {
          problem_number: problem,
          accuracy_rate: accuracyRate.toFixed(2),
        };
      });

      rates.sort((a, b) => b.accuracy_rate - a.accuracy_rate);
      setProblemRates(rates);
    }
  }, [selectedProblemSet, studentData, submit]);

  const handleToggleChange = (checked) => {
    setToggleChecked(checked);
    setViewMode(checked ? 'all' : 'first');
  };

  const getDoughnutChartData = () => {
    const labels = incorrectProblems.map(([problem]) => `문제 ${problem}`);
    const data = incorrectProblems.map(([, count]) => count);

    return {
      labels,
      datasets: [
        {
          label: '틀린 횟수',
          data,
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(255, 159, 64, 0.2)',
            'rgba(199, 199, 199, 0.2)',
            'rgba(83, 102, 255, 0.2)',
            'rgba(83, 102, 200, 0.2)',
            'rgba(83, 200, 200, 0.2)',
          ],
          borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)',
            'rgba(199, 199, 199, 1)',
            'rgba(83, 102, 255, 1)',
            'rgba(83, 102, 200, 1)',
            'rgba(83, 200, 200, 1)',
          ],
          borderWidth: 1,
        },
      ],
    };
  };

  return (
    <div css={style.maincontainer}>
      <div css={style.chartzone}>
        <div>오답 문항 분석</div>
        <Doughnut data={getDoughnutChartData()} />
      </div>
      <div css={style.ranking}>
        <div css={style.rankingTitle}>문제별 오답률 순위</div>
        <ol css={style.rankingList}>
          {problemRates.map((problem, index) => (
            <li key={index} css={style.rankingItem}>
              <span css={style.studentName}>{`문제 ${problem.problem_number}`}</span>
              <span css={style.studentScore}>{`정답률: ${100 - problem.accuracy_rate}%`}</span>
            </li>
          ))}
        </ol>
      </div>
    </div>
  );
};

export default Unsolved;
