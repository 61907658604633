/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';

const ConfirmationModal = ({ isOpen, onClose, onSubmit, handleEvaluationChange, evaluation }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div css={modalStyles.overlay}>
      <div css={modalStyles.content}>
        <h2>AI의 답변에 어느 정도 만족하나요?</h2>
        <p>모든 정답을 한번에 수정하였으면 설문 조사후 제출 버튼을 눌러주세요</p>
        <div css={modalStyles.evaluationOptions}>
          <label>
            <input
              type="radio"
              value="매우만족"
              checked={evaluation === '매우만족'}
              onChange={handleEvaluationChange}
            />
            매우 만족
          </label>
          <label>
            <input
              type="radio"
              value="만족"
              checked={evaluation === '만족'}
              onChange={handleEvaluationChange}
            />
            만족
          </label>
          <label>
            <input
              type="radio"
              value="보통"
              checked={evaluation === '보통'}
              onChange={handleEvaluationChange}
            />
            보통
          </label>
          <label>
            <input
              type="radio"
              value="불만족"
              checked={evaluation === '불만족'}
              onChange={handleEvaluationChange}
            />
            불만족
          </label>
          <label>
            <input
              type="radio"
              value="매우불만족"
              checked={evaluation === '매우불만족'}
              onChange={handleEvaluationChange}
            />
            매우 불만족
          </label>
        </div>
        <div css={modalStyles.buttons}>
          <button onClick={onClose}>취소</button>
          <button onClick={onSubmit}>확인</button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;

const modalStyles = {
  overlay: css`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  `,
  content: css`
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 500px;
    width: 100%;
    text-align: center;
  `,
  evaluationOptions: css`
    label {
      display: block;
      margin: 10px 0;
    }
  `,
  buttons: css`
    margin-top: 20px;

    button {
      margin: 0 10px;
      padding: 10px 20px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
    }

    button:first-of-type {
      background-color: #ccc;
    }

    button:last-of-type {
      background-color: #98510f;
      color: white;
    }
  `,
};
