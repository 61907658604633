// src/theme.js
const theme = {
  colors: {
    brandmain: '#ff9010',
    fontcolor: 'black',
    background: 'white',
    sidebar: 'white',
    brandColor4: '#98510F',
    buttonSelectedBg: 'rgba(0, 0, 0, 0.1)', // 반투명 검은색 배경
    buttonSelectedText: 'black', // 선택된 버튼의 텍스트 색상
    buttonText: '#858992', // 선택되지 않은 버튼의 텍스트 색상
    primary: '#4CAF50',
    secondary: '#008CBA',
    tableHeader: '#f2f2f2',
    text: '#333',
  },
  fonts: {
    main: 'Pretendard-Regular',
  },
};

export default theme;
