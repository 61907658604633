import { useState, useEffect } from 'react';
import api from '../utils/Auth'; // Import the modified axios instance

// Define the API URL, either from environment variables or directly
const API_URL = process.env.REACT_APP_API_URL;

const useProblemSetCount = (problemId) => {
  const [problemSetCount, setProblemSetCount] = useState(null);
  const [error, setError] = useState(null);
  const fetchProblemSetCount = async () => {
    try {
      const response = await api.get(`${API_URL}/students/problem-sets/${problemId}/count`);
      if (response.data) {
        setProblemSetCount(response.data.count);
      }
    } catch (error) {
      console.error('Error fetching problem set count:', error);
      setError(error);
    }
  };

  useEffect(() => {
    if (problemId) {
      fetchProblemSetCount();
    }
  }, [problemId]);

  return { problemSetCount, error };
};

export default useProblemSetCount;
