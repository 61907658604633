/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react';
import TeacherContents from './Teacher/TeacherMain';
import StudentMain from './Student/StudentMain';
import { useNavigate } from 'react-router-dom';
import api from './utils/Auth'; // 수정된 axios 인스턴스를 import 합니다.
import GoogleLoginButton from './googlelogin';
import { css, useTheme } from '@emotion/react';
import logo from './assets/logo.png';
import { jwtDecode } from 'jwt-decode'; // named import로 변경
import AdminMain from './Admin/AdminMain';

const API_URL = process.env.REACT_APP_API_URL;

function Main() {
  const [loginType, setLoginType] = useState('student');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userName, setUserName] = useState('');
  const [studentCredentials, setStudentCredentials] = useState({ id: '', password: '' });
  const navigate = useNavigate();

  const theme = useTheme();
  const style = styles(theme);

  const handleLoginTypeChange = (type) => {
    setLoginType(type);
  };

  useEffect(() => {
    const token = localStorage.getItem('access_token');
    if (token) {
      setIsLoggedIn(true);
      checkUserRole(token);
    } else {
      navigate('/');
    }
  }, [navigate,userName]);
 
  const checkUserRole = async (token) => {

        if (!token) {
          navigate('/');
          return;
        }

        try {
          const decodedToken = jwtDecode(token);
          const role = decodedToken.user_role; // 예시: 역할 정보가 토큰에 포함되어 있다고 가정합니다.
          if (role === 'teacher') {
            setUserName('Teacher');
          } else if (role === 'student') {
            setUserName('Student');
          } else if (role === 'admin') {
            setUserName('admin');
          } 
          else {
            throw new Error('Invalid role');
          }
        } catch (error) {
          console.error(error);
        }
  };

  const handleStudentLogin = async (event) => {
    event.preventDefault();
    try {
      const response = await api.post(`${API_URL}/auth/login`, {
        class_code: studentCredentials.id.trim(),
        password: studentCredentials.password,
      });

      if (response.data && response.data.access_token) {
        setIsLoggedIn(true);
        localStorage.setItem('access_token', response.data.access_token);
        checkUserRole(response.data.access_token);
      } else {
        alert('학급ID와 비밀번호를 확인해 주세요!')
        setIsLoggedIn(false);
        alert('로그인에 실패했습니다.');
      }
    } catch (error) {
      alert('학급ID와 비밀번호를 확인해 주세요!')

      console.error('로그인 요청 에러:', error);
      setIsLoggedIn(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setStudentCredentials((prev) => ({ ...prev, [name]: value }));
  };

  let content;
  if (isLoggedIn) {
    if (userName === 'Teacher') {
      content = <TeacherContents />;
    } else if (userName === 'Student') {
      content = <StudentMain />;
    } else if (userName === 'admin') {
      content = <AdminMain />;
    }
  } 
   else {
    content = (
      <div css={style.container}>
        <header css={style.header}>
          <img src={logo} alt="Logo" css={style.logo} />
        </header>
        <div css={style.mainContent}>
          <h1 css={style.title}>가볍다, 빠르다, 단순하다</h1>
          <h2 css={style.subtitle}>학생은 선생님께 받은 로그인 정보로 입장.</h2>
          <div css={style.toggleContainer}>
            <button
              css={loginType === 'student' ? style.activeToggle : style.inactiveToggle}
              onClick={() => handleLoginTypeChange('student')}
            >
              학생 로그인
            </button>
            <button
              css={loginType === 'teacher' ? style.activeToggle : style.inactiveToggle}
              onClick={() => handleLoginTypeChange('teacher')}
            >
              선생님 로그인
            </button>
          </div>

          <form css={style.form} onSubmit={handleStudentLogin}>
            {loginType === 'student' ? (
              <>
                <input
                  type="text"
                  name="id"
                  placeholder="학급 ID 입력"
                  css={style.input}
                  value={studentCredentials.id}
                  onChange={handleInputChange}
                />
                <input
                  type="password"
                  name="password"
                  placeholder="참여코드 입력"
                  css={style.input}
                  value={studentCredentials.password}
                  onChange={handleInputChange}
                />
                <button type="submit" css={style.loginButton}>로그인</button>
              </>
            ) : (
              <GoogleLoginButton setIsLoggedIn={setIsLoggedIn} checkUserRole={checkUserRole} />
            )}
          </form>
          <h3>contact: yhlee@dnue.ac.kr </h3>
        </div>
      </div>
    );
  }

  return <div className="App">{content}</div>;
}

const styles = (theme) => ({
  container: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f6e6d8;
  `,
  header: css`
    width: 100%;
    padding: 10px 20px;
    display: flex;
    justify-content: flex-start;
    position: absolute;
    top: 0;
    left: 0;
  `,
  logo: css`
    width: 90px;
    height: 30px;
  `,
  mainContent: css`
    text-align: center;
    margin-top: 60px;
  `,
  title: css`
    color: ${theme.colors.brandColor4};
    font-size: 22px;
    margin-bottom: 10px;
  `,
  subtitle: css`
    color: #333;
    font-size: 28px;
    margin-bottom: 20px;
  `,
  toggleContainer: css`
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  `,
  activeToggle: css`
    background-color: rgba(0, 0, 0, 0.1);
    width: 50%;
    height: 40px;
    color: black;
    border: none;
    font-size: 18px;
    font-weight: bold;
    margin: 0 10px;
    cursor: pointer;
    border-radius: 25px;
  `,
  inactiveToggle: css`
    background-color: rgba(0, 0, 0, 0);
    width: 50%;
    color: #a4b0c1;
    height: 40px;
    border: none;
    font-weight: bold;
    font-size: 18px;
    margin: 0 10px;
    cursor: pointer;
    border-radius: 25px;
    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
  `,
  form: css`
    display: flex;
    background-color: white;
    padding: 30px;
    border-radius: 20px;
    flex-direction: column;
    align-items: center;
  `,
  input: css`
    width: 100%;
    height: 30px;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 16px;
  `,
  loginButton: css`
    background-color: ${theme.colors.brandColor4};
    width: 100%;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    margin-top: 30px;
    font-size: 16px;
    cursor: pointer;
    &:hover {
      background-color: ${theme.colors.brandColor4};
    }
  `,
});

export default Main;
