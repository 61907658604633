import { useState, useEffect } from 'react';
import api from '../utils/Auth'; // 수정된 axios 인스턴스를 import 합니다.

// API_URL을 환경 변수로 설정하거나 직접 정의하세요.
const API_URL = process.env.REACT_APP_API_URL;

const useInteractionsCount = () => {
  const [interactionsCount, setInteractionsCount] = useState(null);
  const [error, setError] = useState(null);

  const fetchInteractionsCount = async () => {
    try {
      const response = await api.get(`${API_URL}/students/interactions/count`);
      if (response.data) {

        setInteractionsCount(response.data.count);
      }
    } catch (error) {
      console.error('Error fetching interactions count:', error);
      setError(error);
    }
  };

  useEffect(() => {
    fetchInteractionsCount();
  }, []);

  return { interactionsCount, error };
};

export default useInteractionsCount;
