/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { css, useTheme } from '@emotion/react';
import useUserData from '../hooks/useUserData'; // Custom hook import path
import useProblemSetCount from '../hooks/useProblemSetCount'; // Import the useProblemSetCount hook
import { formatDate } from '../utils/formatDate';
const Incorrectnotes = ({ userName }) => {
  const navigate = useNavigate();
  const { userdata, selectedProblemSet, setSelectedProblemSet, loading, error } = useUserData();
  const theme = useTheme();

  const [selectedProblemSetId, setSelectedProblemSetId] = useState(null);

  // Use the useProblemSetCount hook to get the count
  const { problemSetCount, error: countError } = useProblemSetCount(selectedProblemSetId);

  useEffect(() => {
    if (selectedProblemSet && userdata.length > 0) {
      const initialProblemSet = userdata.find(item => item.problem_set_name === selectedProblemSet);
      if (initialProblemSet) {
        setSelectedProblemSetId(initialProblemSet.problem_set_id);
      }
    }
  }, [selectedProblemSet, userdata]);

  const handleButtonClick = (id) => {
    navigate(`/solve/${id}`);
  };

  const handleProblemSetClick = (problemSetName, problemSetId) => {
    setSelectedProblemSet(problemSetName);
    setSelectedProblemSetId(problemSetId);
  };

  const filteredUserdata = selectedProblemSet
    ? userdata.filter(item => item.problem_set_name === selectedProblemSet)
    : userdata;

  // Ensure unique problem sets
  const uniqueProblemSets = Array.from(
    userdata.reduce((map, item) => {
      if (!map.has(item.problem_set_name)) {
        map.set(item.problem_set_name, item.problem_set_id);
      }
      return map;
    }, new Map())
  ).map(([name, id]) => ({ name, id }));

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div css={styles.teacherContent}>
      <div css={styles.startContent}>
        <div css={styles.welcomeContainer}>
          <div css={styles.welcomeMessage}>내가 해결한 문제</div>
        </div>
        <div css={styles.problemSetButtons}>
          {uniqueProblemSets.map((problemSet, index) => (
            <button
              key={index}
              css={[styles.problemSetButton(theme), selectedProblemSet === problemSet.name && styles.active(theme)]}
              onClick={() => handleProblemSetClick(problemSet.name, problemSet.id)}
            >
              {problemSet.name}
            </button>
          ))}
        </div>
      </div>
      <div css={styles.downzone}>
        <div css={styles.countInfo}>
          {selectedProblemSet} 문제집 수정가능 횟수: {problemSetCount !== null ? `${problemSetCount}/2` : '수정할수 있는 문제집이 없습니다'}
        </div>
        <div css={styles.buttonContainer}>
          {filteredUserdata.map((item, index) => (
            <button
              key={index}
              onClick={() => handleButtonClick(item.solved_at)}
              css={[styles.buttonStyle, index === 0 ? styles.firstOrder(theme) : styles.otherOrder]}
            >
              {`순서: ${index === 0 ? '최초' : `수정${index}`}, 날짜: ${formatDate(item.solved_at)}, 문제집: ${item.problem_set_name}, 점수: ${item.score}`}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

const styles = {
  teacherContent: css`
    width: 100%;
    height: 100%;
  `,
  startContent: css`
    text-align: center;
    margin: 20px 0;
  `,
  welcomeMessage: css`
    font-size: 24px;
    font-weight: bold;
  `,
  problemSetButtons: css`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 10px 0;
  `,
  problemSetButton: (theme) => css`
    padding: 10px 20px;
    margin: 5px;
    font-size: 16px;
    cursor: pointer;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 5px;
    transition: background-color 0.3s, border-color 0.3s, color 0.3s;

    &:hover {
      background-color: #e0e0e0;
      border-color: #bbb;
    }
  `,
  active: (theme) => css`
    background-color: ${theme.colors.brandColor4};
    color: white;
    border-color: ${theme.colors.brandColor4};

    &:hover {
      background-color: #eb9749;
      border-color: #eb9749;
    }
  `,
  downzone: css`
    width: 100%;
    overflow-y: auto;
  `,
  countInfo: css`
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
  `,
  buttonContainer: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 100%;
  `,
  buttonStyle: css`
    padding: 10px 15px;
    width: 80%;
    background-color: #f8f9fa;
    color: #333;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, border-color 0.3s, color 0.3s;

    &:hover {
      background-color: #e9ecef;
      border-color: #bbb;
    }
  `,
  firstOrder: (theme) => css`
    background-color: ${theme.colors.brandColor4};
    color: white;
    border-color: ${theme.colors.brandColor4};

    &:hover {
      background-color: #eb9749;
      border-color: #eb9749;
    }
  `,
  otherOrder: css`
    background-color: #6c757d;
    color: white;
    border-color: #6c757d;

    &:hover {
      background-color: #5a6268;
      border-color: #545b62;
    }
  `,
};

export default Incorrectnotes;
